<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="auth-container sm:mx-auto sm:w-full sm:max-w-sm"
    [ngStyle]="!isLoggedIn ? {'max-width': '24rem'}: {'max-width': '30rem'}">
  <div class="logo" (click)="onNavigateHome()">
    <img src="/assets/img/materlog_logo.svg" alt="materlog-logo" />
  </div>

  <div class="form" *ngIf="!isLoggedIn">
    <div class="title">Create your free account</div>
    <form [formGroup]="signUpForm" class="form-group">
      <div class="textfield">
        <label class="textfield__label" for="first_name">First name</label>
        <input
          [ngClass]="{
            'textfield--error':
              signUpForm.controls.first_name.invalid && signUpForm.controls.first_name.touched && isSubmitted
          }"
          type="text"
          id="first_name"
          formControlName="first_name"
          class="form-controls"
          placeholder="First name"
        />
        <div
          class="textfield__error-message"
          *ngIf="!signUpForm.controls.first_name.value && signUpForm.controls.first_name.touched && isSubmitted"
        >
          Enter your first name
        </div>
        <div class="textfield__error-message" *ngIf="signUpForm.controls.first_name.errors?.minlength && isSubmitted">
          First name must be at least 2 characters
        </div>
        <div class="textfield__error-message" *ngIf="signUpForm.controls.first_name.errors?.maxlength && isSubmitted">
          First name must be less than 32 characters
        </div>
      </div>

      <div class="textfield">
        <label class="textfield__label" for="last_name">Last name</label>
        <input
          [ngClass]="{
            'textfield--error':
              signUpForm.controls.last_name.invalid && signUpForm.controls.last_name.touched && isSubmitted
          }"
          type="text"
          id="last_name"
          formControlName="last_name"
          class="form-controls"
          placeholder="Last name"
        />
        <div
          class="textfield__error-message"
          *ngIf="!signUpForm.controls.last_name.value && signUpForm.controls.last_name.touched && isSubmitted"
        >
          Enter your last name
        </div>
        <div class="textfield__error-message" *ngIf="signUpForm.controls.last_name.errors?.minlength && isSubmitted">
          Last name must be at least 2 characters
        </div>
        <div class="textfield__error-message" *ngIf="signUpForm.controls.last_name.errors?.maxlength && isSubmitted">
          Last name must be less than 32 characters
        </div>
      </div>

      <div class="textfield">
        <label class="textfield__label" for="organization_name">Company</label>
        <input
          [ngClass]="{
            'textfield--error':
              signUpForm.controls.organization_name.invalid &&
              signUpForm.controls.organization_name.touched &&
              isSubmitted
          }"
          type="text"
          id="organization_name"
          formControlName="organization_name"
          class="form-controls"
          placeholder="Company name"
        />
        <div
          class="textfield__error-message"
          *ngIf="
            !signUpForm.controls.organization_name.value && signUpForm.controls.organization_name.touched && isSubmitted
          "
        >
          Enter your company name
        </div>
        <div
          class="textfield__error-message"
          *ngIf="signUpForm.controls.organization_name.errors?.minlength && isSubmitted"
        >
          Company name must be at least 2 characters
        </div>
        <div
          class="textfield__error-message"
          *ngIf="signUpForm.controls.organization_name.errors?.maxlength && isSubmitted"
        >
          Company name must be less than 32 characters
        </div>
      </div>

      <div class="textfield">
        <label class="textfield__label" for="email">Work email</label>
        <input
          [ngClass]="{
            'textfield--error':
              (emailExists ||
                (signUpForm.controls.email.invalid && signUpForm.controls.email.touched) ||
                signUpForm.controls.email.errors?.pattern) &&
              signUpForm.controls.email.touched &&
              isSubmitted
          }"
          type="text"
          id="email"
          formControlName="email"
          class="form-controls"
          placeholder="name@company.com"
        />
        <div class="textfield__error-message" *ngIf="emailExists">
          An account with this email already exists. Sign in <a href="/login">here</a>
        </div>
        <div
          class="textfield__error-message"
          *ngIf="signUpForm.controls.email.invalid && signUpForm.controls.email.touched && isSubmitted"
        >
          Enter a valid email address
        </div>
      </div>

      <!--
      <div class="textfield">
        <label class="textfield__label" for="phone">Phone</label>
        <input type="text" id="phone" formControlName="phone" class="form-controls" placeholder="(123) 456-7890" />
        <div
          class="textfield__error-message"
          *ngIf="signUpForm.controls.phone.invalid && signUpForm.controls.phone.touched && isSubmitted"
        >
          Phone must be less than 20 characters
        </div>
      </div>
      -->


      <div class="textfield">
        <label class="textfield__label" for="password">Password</label>
        <input
          [ngClass]="{
            'textfield--error':
              signUpForm.controls.password.invalid && signUpForm.controls.password.touched && isSubmitted
          }"
          type="password"
          id="password"
          formControlName="password"
          class="form-controls"
          placeholder="********"
        />
        <div
          class="textfield__error-message"
          *ngIf="signUpForm.controls.password.invalid && signUpForm.controls.password.touched && isSubmitted"
        >
          Password must be at least 8 characters, including 1 special character
        </div>
      </div>
      <div class="textfield__error-message" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>

      <button
        pButton
        type="submit"
        label="Continue"
        class="button-primary p-button-raised"
        (click)="onSubmit()"
      ></button>
    </form>

    <div class="disclaimer">
      <div style="margin-bottom: 6px">
        By creating an account, you agree to the <a href="https://materlog.com/terms">Terms of Service</a> and
        <a href="https://materlog.com/privacy">Privacy Policy</a>
      </div>
      Already have an account?
      <div class="link" (click)="onNavigateToSignIn()">Sign in</div>
    </div>
  </div>

  <ng-container *ngIf="!isSubmittedCompany && !isSubmittedRole && !isSubmittedTeam && isLoggedIn">
    <div class="title text-center">What type of company?</div>
    <div class="subtitle text-center subtitle--grey">This will help us personalize your experience on Materlog.</div>

    <p-selectButton
      styleClass="container-select-button"
      [options]="companyTypes"
      [(ngModel)]="selectedCompanyType"
      optionLabel="value"
      optionValue="id"
      [multiple]="true"
    ></p-selectButton>

    <div class="container-submit">
      <button
        pButton
        type="button"
        label="Skip"
        class="p-button-raised p-button-text p-button-plain"
        (click)="onSkipCompany()"
      ></button>

      <button
        pButton
        type="button"
        label="Continue"
        class="button-primary p-button-raised"
        (click)="onSubmitCompanyType()"
      ></button>
    </div>
  </ng-container>

  <ng-container *ngIf="isSubmittedCompany && !isSubmittedRole && !isSubmittedTeam">
    <div class="title text-center">What’s your role?</div>
    <div class="subtitle text-center subtitle--grey">Choose one that best applies.</div>

    <p-selectButton
      styleClass="container-select-button longer-width"
      [options]="roleTypes"
      [(ngModel)]="selectedRoleType"
      optionLabel="value"
      optionValue="id"
    ></p-selectButton>

    <div class="container-submit">
      <button
        pButton
        type="button"
        label="Skip"
        class="p-button-raised p-button-text p-button-plain"
        (click)="onSkipRole()"
      ></button>

      <button
        pButton
        type="button"
        label="Continue"
        class="button-primary p-button-raised"
        (click)="onSubmitRoleType()"
      ></button>
    </div>
  </ng-container>

  <!-- <ng-container *ngIf="isSubmittedCompany && isSubmittedRole && !isSubmittedTeam">
    <div class="title">Who do you work with ?</div>
    <div class="subtitle subtitle--grey">Keep your whole team on the same page.</div>

    <div class="container-team">
      <form [formGroup]="teamForm" class="form-group">
        <div class="textfield">
          <input
            type="text"
            id="first_member"
            formControlName="first_member"
            class="form-controls"
            placeholder="name@[companydomain].x"
          />
        </div>
        <div class="textfield">
          <input
            type="text"
            id="second_member"
            formControlName="second_member"
            class="form-controls"
            placeholder="name@[companydomain].x"
          />
        </div>
        <div class="textfield">
          <input
            type="text"
            id="third_member"
            formControlName="third_member"
            class="form-controls"
            placeholder="name@[companydomain].x"
          />
        </div>
        <div class="textfield">
          <label class="textfield__label" for="invite_link"><i class="bi bi-link-45deg"></i>Invite link</label>
          <div class="invite">
            <input
              type="text"
              id="invite_link"
              formControlName="invite_link"
              class="form-controls"
              placeholder="name@[companydomain].x"
            />
            <button pTooltip="Copied to clipboard" tooltipEvent="focus" type="button" (click)="copyLink()">
              Copy link
            </button>
          </div>
        </div>
      </form>
      <div class="actions">
        <button
          pButton
          type="button"
          label="Skip"
          class="p-button-raised p-button-text p-button-plain"
          (click)="onSkip()"
        ></button>

        <button
          pButton
          type="button"
          label="Continue"
          class="button-primary p-button-raised"
          (click)="onSubmitTeamMembers()"
        ></button>
      </div>
    </div>
  </ng-container> -->
</div>
</div>
