import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { SelectedViewModel } from '../../models/selected-view.model';
import { Store } from '@ngrx/store';
import { ElementRef } from '@angular/core';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { ActiveJobModel } from '../../models/active-job.model';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { OrderService } from '../../services/order.service';
import { JobsService } from '../../services/job.service';
import { DashboardService } from '../../services/dashboard.service';
import { CommonConstants } from 'src/app/common/constants/common.constants';



@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  @Input() selectedView?: SelectedViewModel;
  @Input() dropdownOptions?: any;
  @Input() activeJobsList!: ActiveJobModel[];
  @Input() allMailData: any;
  
  @ViewChild('noteOrder') noteOrder!: ElementRef;
  @ViewChild('noteSupplier') noteSupplier!: ElementRef;

  isLoading: boolean = false;
  skeletonItems: any[] = [];
  sidePanelOpen: boolean = true;
  selectedId: any = 0;
  notifications: any = [];

  todayDate: any = undefined;

  notifTypeSupplier = 0;
  notifTypeSupplierCred = 1;
  notifTypeJobDone = 2;
  notifTypeInvalidCreds = 3;
  notifTypeItemStatus = 4;
  notifTypeMissingItems = 5;
  notifTypeNewOrder = 6;
  notifTypeNewSupplier = 7;

  currentItem: any = undefined;
  newItem: boolean = false;

  results: any = undefined;

  statusOptions: any = undefined;
  statusColorOptions: any = undefined;

  constructor(
    private jobService: JobsService,
    private cdr: ChangeDetectorRef,
    private orderService: OrderService,
    private store: Store<IApplicationState>,
    private dashboardService: DashboardService,
    private djv: DashboardJobView,
    private dashboardActions: DashboardActions
  ) {
    (window as any)['note'] = this;
  }
  
  ngOnInit() {
    this.getTodayDateInEST();
    this.generateItems();
    this.pollGetNotifications();
  }

  getTodayDateInEST() {
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'America/New_York'
    });
  
    const parts: any = formatter.formatToParts(new Date());
    const year = parts.find((part: any) => part.type === 'year').value;
    const month = parts.find((part: any) => part.type === 'month').value;
    const day = parts.find((part: any) => part.type === 'day').value;
  
    this.todayDate =`${year}-${month}-${day}`;
  }

  generateItems() {
    for (let i = 0; i < 10; i++) {
      const randomWidth = Math.floor(Math.random() * (20 - 5 + 1)) + 5;
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }
  }

  itemClicked(item: any) {
    this.newItem = true;
    item.seen = true;
    this.cdr.detectChanges();
    this.newItem = false;
    this.currentItem = item;
    setTimeout(() => {
      this.setScrollbar('.all-info-container');
    }, 200);
    this.setNotificationCnt();
    this.dashboardService.updateAlert(item.id, {is_seen: true}).subscribe(() => {});
  }

  formatDate(item: any): string {
    if (item.date == 'Today') return item.date;
    let date: any = new Date(item.date);
    date = date.toISOString();
    let cur = date.split('T')[0];
    if (cur == this.todayDate) return 'Today';
    cur = cur.split('-');
    cur = cur.slice(-2).join('/');
    return cur;
  }

  backgroundForItem(item: any) {
    let colorId: any = undefined
    if (item.type == this.notifTypeJobDone) {
      colorId = this.itemStatusType('Delivered');
      colorId = this.itemStatusColor(colorId);
      return colorId || '#5fc64c';
    } else if (item.type == this.notifTypeNewOrder ||
              item.type == this.notifTypeNewSupplier) {
      colorId = this.itemStatusType('Ordered');
      colorId = this.itemStatusColor(colorId);
      return colorId || '#ed7840';
    }
    if (!item.statuses) {
      return 'white';
    }
    if (colorId = this.itemContainsStatus(item, 'Delivered')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Pending payment')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Shipped')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Tracking issues')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Ready to be scheduled')) {
      colorId = this.itemStatusColor(colorId);
    }
    return colorId ? colorId: 'white';
  }

  borderForItem(item: any) {
    let colorId: any = undefined
    if (item.type == this.notifTypeJobDone ||
      item.type == this.notifTypeNewOrder ||
      item.type == this.notifTypeNewSupplier) {  
        return '';
    }
    if (!item.statuses) {
      return '1px solid rgba(0, 0, 255, 0.5)';
    }
    if (colorId = this.itemContainsStatus(item, 'Delivered')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Pending payment')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Shipped')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Tracking issues')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Ready to be scheduled')) {
      return '';
    }
    return '1px solid rgba(0, 0, 255, 0.5)';
  }

  cardOpenHeight: number = 190;
  cardIsOpenChange(event: any) {
    setTimeout(() => {
      this.cardOpenHeight = this.noteOrder.nativeElement.clientHeight;
    }, 100);
  }
  getMaxHeight() {
    // note-display-text + note-supplier-text * 2 + single-card + supplier-creds
    return window.innerHeight - (60 * 3 + this.cardOpenHeight + 170 + 95);
  }

  setScrollbar(v: string) {
    let jdisplay = document.querySelector(v);
    if (jdisplay) {
      jdisplay.addEventListener('mouseenter', function() {
        (jdisplay as any).classList.add('custom-scrollbar');
        (jdisplay as any).classList.remove('custom-scrollbar-close');
      });
      jdisplay.addEventListener('mouseleave', function() {
        (jdisplay as any).classList.remove('custom-scrollbar');
        (jdisplay as any).classList.add('custom-scrollbar-close');
      });
    }
  }

  itemContainsStatus(item: any, type: string) {
    let foundId = this.itemStatusType(type);
    return item.statuses.find((s: any) => s == foundId);
  }

  itemStatusType(type: string) {
    let which = this.statusOptions.find((s: any) => s.value == type);
    return which ? which.id: undefined;
  }

  itemStatusColor(type: number) {
    let which = this.statusColorOptions.find((s: any) => s.id == type);
    return which ? which.value: '';
  }

  sortDates(obj1: any, obj2: any) {
    if (obj1.date === 'Today') return -1;
    if (obj2.date === 'Today') return 1;
  
    const date1: any = new Date(obj1.date);
    const date2: any = new Date(obj2.date);
    return date2 - date1;
  }

  createNotifications(results: any) {
    let output: any = [];
    let job_type = this.dropdownOptions?.alert_types?.find((a: any) => a.value == 'Job')?.id;
    let order_type = this.dropdownOptions?.alert_types?.find((a: any) => a.value == 'Order')?.id;
    let supplier_type = this.dropdownOptions?.alert_types?.find((a: any) => a.value == 'Supplier')?.id;
    let receiver_type = this.dropdownOptions?.alert_types?.find((a: any) => a.value == 'Receiver')?.id;
    results.forEach((result: any) => {
      if (result.alert_type == job_type || result.alert_type == receiver_type) {
        let which = undefined;
        if (result.alert_type == job_type) {
          which = this.dropdownOptions.job_alerts.find((a: any) => result.job_alert == a.id)?.id;
        } else {
          which = this.dropdownOptions.receiver_alerts.find((a: any) => result.receiver_alert == a.id)?.id;
        }
        if (!which) return;

        let job_id = result.job;
        let job = this.allMailData?.jobsArray?.find((j: any) => j.id == job_id);
        if (!job) {
          return
        };
        let cur: any = {
            id: result.id,
            seen: result.is_seen,
            date: result.created_datetime,
            job_id: job.id,
            job_name: job.value,
        }
        if (result.alert_type == job_type) {
          if (which == 1) { // no address
            cur.text = `Missing job address: ${job.value}`;
          } else if (which == 2) { // no receiver
            cur.text = `No receiver: ${job.value}`;
          } else if (which == 4) { // job complete
            cur.text = `Job complete: ${job.value}`;
          } else if (which == 5) { // new job
            cur.text = `New job: ${job.value}`;
          }
        } else {
          let rname = result.receiver_name;
          if (which == 1) { // no address
            cur.text = `No receiver address: ${rname}`;
          } else if (which == 2) { // no email
            cur.text = `No receiver email: ${rname}`;
          } else if (which == 3) { // invalid email
            cur.text = `Invalid receiver email: ${rname}`;
          }
        }

        if (cur.text) {
          output.push(cur);
        }
      } else if (result.alert_type == order_type) {
        let which = this.dropdownOptions.order_alerts.find((a: any) => result.order_alert == a.id)?.id;
        if (!which) {
          return;
        }
        let order_id = result.order;
        let order = this.allMailData?.curOrders?.find((o: any) => o.id == order_id);
        if (!order) {
          return
        };
        let cur: any = {
            id: result.id,
            seen: result.is_seen,
            date: result.created_datetime,
            order_id: order.id,
            order_name: order.value,
            job_id: order.job_id,
            job_name: order.job_name,
            supplier_id: order.supplier_id,
            supplier_name: order.supplier_name,
        }
        if (which == 1) { // missing supplier
          cur.text = `Missing supplier: ${order.value}`;
        } else if (which == 2) { // no items
          cur.text = `Missing items: ${order.value}`;
        } else if (which == 3) { // item order but only po
          cur.text = `Needs order confirmation: ${order.value}`;
        } else if (which == 4) { // order expiring
          cur.text = `Order is expiring: ${order.value}`;
        } else if (which == 5) { // invalid tracking
          cur.text = `Invalid tracking: ${order.value}`;
        } else if (which == 6) { // missing tracking
          cur.text = `Missing tracking: ${order.value}`;
        } else if (which == 7) { // ready to be scheduled
          cur.text = `Ready to be scheduled: ${order.value}`;
        } else if (which == 8) { // new comment
          cur.text = `Comment was made: ${order.value}`;
        } else if (which == 9) { // new order
          cur.text = `Order created: ${order.value}`;
        } else if (which == 10) { // confirm order details
          cur.text = `Confirm order details for tracking: ${order.value}`;
        }
        if (cur.text) {
          output.push(cur);
        }
      } else if (result.alert_type == supplier_type) {
        let which = this.dropdownOptions.supplier_alerts.find((a: any) => result.supplier_alert == a.id)?.id;
        if (!which) {
          return;
        }
        let supplier = this.allMailData.supArray.find((s: any) => s.id == result.supplier);
        if (!supplier) {
          return;
        }
        let order = this.allMailData?.curOrders?.find((o: any) => o.supplier_id == result.supplier);
        let cur: any = {
          id: result.id,
          seen: result.is_seen,
          date: result.created_datetime,
          supplier_id: supplier.id,
          supplier_name: supplier.value,
          order_id: order?.id,
          order_name: order?.value,
      }
        if (which == 1) { // new supplier
          cur.text = `New supplier: ${supplier.value}`;
        } else if (which == 2) { // no email
          cur.text = `No supplier email: ${supplier.value}`;
        } else if (which == 3) { // invalid email
          cur.text = `Invalid supplier email: ${supplier.value}`;
        } else if (which == 4) { // no creds
          cur.text = `No supplier creds: ${supplier.value}`;
        } else if (which == 5) { // invalid creds
          cur.text = `Invalid supplier creds: ${supplier.value}`;
        } else if (which == 6) { // account missing
          cur.text = `Supplier account number missing: ${supplier.value}`;
        }
        if (cur.text) {
          output.push(cur);
        }
      } else if (result.alert_type == receiver_type) {
        let which = this.dropdownOptions.receiver_alerts.find((a: any) => result.receiver_alert == a.id)?.id;
        if (!which) return;
        if (which == 1) { // no address

        } else if (which == 2) { // no email
          
        } else if (which == 3) { // invalid email

        }
      } 
    })
    output.sort(this.sortDates);
    this.notifications = output;
    this.currentItem = this.notifications?.length ? this.notifications[0] : undefined;
    setTimeout(() => {
      this.setScrollbar('.notifications-list-short');
    }, 200);

  }

  get dropdownRetrieved() {
    return this.dropdownOptions.document_types  &&
    this.dropdownOptions.job_id  &&
    this.dropdownOptions.status  &&
    this.dropdownOptions.status_colors  &&
    this.dropdownOptions.supplier_id  &&
    this.dropdownOptions.unit_of_measure &&
    this.allMailData?.curOrders &&
    this.allMailData?.supArray;
  }

  orderDeleted() {
    this.currentItem = undefined;
    this.getNotifications();
    this.dashboardActions.requestUncategorizedView(
      { include_items: CommonConstants.include_items.uncategorized });
  }

  setNotificationCnt() {
    let cnt = this.notifications.filter((n: any) => !n.seen).length;
    this.djv.do_set_notification_cnt(cnt);
  }

  deleteAlert(event: any, alert: any) {
    event.stopPropagation();
    event.preventDefault();
    if (alert.id == this.currentItem.id) {
      this.currentItem = undefined;
    }
    this.notifications = this.notifications.filter((n: any) => n.id != alert.id);
    if (this.notifications.length) {
      this.currentItem = this.notifications[0];
    }
    this.setNotificationCnt();
    this.dashboardService.updateAlert(alert.id, {is_ignored: true}).subscribe(() => {});
   }

  pollGetNotifications() {
    let outer = this;
    this.isLoading = true;
    var pollInterval = setInterval(function() {
      if (outer.dropdownRetrieved) {
        clearInterval(pollInterval);
        outer.getNotifications();
      }
    }, 20);
    
  }

  getNotifications() {
    this.dashboardService.getNotifications().subscribe((result: any) => {
      this.isLoading = false;
      this.results = result;
      this.createNotifications(result || []);
    })
  }

  get notificationBanner() {
    let defaultName = 'Notifications';
    if (this.isLoading) return defaultName
    return this.notifications?.length ? defaultName : 'No notifications!';
  }
}