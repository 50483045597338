import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { AuthenticationActions } from '../../state/actions/authentication.actions';
import { OrganizationService } from 'src/app/company-settings/services/organization.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'materlog-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css']
})
export class InviteUserComponent extends OnDestroyMixin() implements OnInit {
  baseUrl: string = environment.ENV.BASE_URL;
  encryptedData: string = '';
  routeId: any = '';

  constructor(
    public router: Router,
    private injector: Injector,
    private route: ActivatedRoute,
    private store: Store<IApplicationState>,
    private authenticationActions: AuthenticationActions,
    private orgService: OrganizationService,
    private httpClient: HttpClient
  ) {
    super();
    this.routeId = route.snapshot.params.id;
    (window as any)['invite'] = this;
  }


  ngOnInit() {
    // Get the 'encrypted_data' parameter from the route
    this.orgService.orgCreateNewUser(this.baseUrl, this.routeId).subscribe((result: any) => {})
  }

  resetPassword() {
    this.router.navigate(['forgot-password']);
  }
  
}
