<div class="credentials-container" #credentialContainer
    [ngStyle]="getDynamicStyles()">
  <div *ngIf="!notificationsView && allDataRetrieved" class="header">
    <div class="header-left supplier-filter-button">
      <div class="supplier-filter-button active-orders supplier-button-padding"
          (click)="doFilterActiveSuppliers(true)"
          [ngClass]="filterMySuppliers ? 'active-supplier-button' : ''">
        Active orders
      </div>
      <div class="supplier-filter-button all-suppliers supplier-button-padding"
          (click)="doFilterActiveSuppliers(false)"
          [ngClass]="filterMySuppliers ? '': 'active-supplier-button'">
        All
      </div>
    </div>
    <div class="header-right">
      <div class="add-supplier" (click)="addSupplier()">
        + Supplier
      </div>
      <div class="search-input">
        <div>
          <input
            class="px-2 py-1 rounded-full text-xs border border-neutral-300 input-element"
            type="text"
            id="filter-text-box"
            placeholder="Search..."
            [(ngModel)] = "searchText"
            (input)="onSearchChange()"
          />
        </div>
      </div>
    </div>

  </div>
  <div style="margin-left: 60px; margin-top: 60px" *ngIf="!allDataRetrieved">
    <p-skeleton width="30rem" height="30px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
    <div *ngFor="let item of skeletonItems">
      <p-skeleton [width]="item.width" [height]="'20px'" styleClass="mb-2" borderRadius="16px"></p-skeleton>
    </div>
  </div>
  <div class="size-change custom-scrollbar-close" *ngIf="allDataRetrieved">
    <div class="supplier-headers">
      <div class="all-supplier-width" style="position: relative; top: 2px">
        <p-checkbox
          [(ngModel)]="allSupplierCheckbox"
          name="suppliers"
          [binary]="true"
          (onChange)="allSuppliersClicked()">
        </p-checkbox>
      </div>
      <div class="supplier-header-name" *ngFor="let c of columnsSupplierCredentials"
          [ngClass]="columnWidthForName(c)">
        {{c}}
        <div class="supplier-contact-info" *ngIf="c == 'CONTACTS'">
          <i class="bi bi-info-lg"></i>
          <div class="supplier-contact-tooltip supplier-contact-info-tooltip">
            When a contact email is provided and the tracking method is email, we will email that contact. Otherwise, we will email the generic business email, if applicable.
          </div>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 4px"></div>
    <div class="single-supplier-container custom-scrollbar-close">
      <div class="single-supplier-row"
            [ngClass]="filteredSuppliers.length > 1 ? 'multi-supplier-row': ''"
            [ngStyle]="{'background-color': supplier.checkbox ? '#f4f4f4' : ''}"
            *ngFor="let supplier of filteredSuppliers; let isLast = last">
        <div class="all-supplier-width" style="position: relative; top: -1px">
          <p-checkbox 
            [(ngModel)]="supplier.checkbox"
            name="suppliers"
            [binary]="true"
            (onChange)="singleSupplierClicked(supplier)">
          </p-checkbox>
        </div>
        <div class="default-col-width supplier-text">
          {{supplier.name}}
        </div>
        <div class="long-col-width supplier-basics">
          <div class="basic-row">
            <i class="bi bi-link-45deg basic-icon" style="font-size: 13px"></i>
            <input class="basic-row-input item-text-input"
              type="text"
              (focus)="startEditingCredential($event, supplier, 'website')"
              (keydown.enter)="inputEnter($event)"
              (blur)="stopEditingCredential($event, supplier, 'website')"
              [(ngModel)]="supplier.credentials.website"/>
          </div>
          <div class="basic-row">
            <i class="bi bi-envelope basic-icon"></i>
            <input class="basic-row-input item-text-input"
              type="text"
              (focus)="startEditingCredential($event, supplier, 'email')"
              (keydown.enter)="inputEnter($event)"
              (blur)="stopEditingCredential($event, supplier, 'email')"
              [(ngModel)]="supplier.credentials.email"/>
          </div>
          <div class="basic-row">
            <i class="bi bi-telephone-fill basic-icon"></i>
            <input class="basic-row-input item-text-input"
              type="text"
              (focus)="startEditingCredential($event, supplier, 'phone')"
              (keydown.enter)="inputEnter($event)"
              (blur)="stopEditingCredential($event, supplier, 'phone')"
              [(ngModel)]="supplier.credentials.phone"/>
          </div>
        </div>
        <div class="long-col-width">
          <div class="supplier-contact-container custom-scrollbar-close">
            <div class="supplier-contact" *ngFor="let sc of supplier.contacts">
              <div class="delete-supplier-contact">
                <i class="bi bi-x" (click)="deleteSupplierContact(supplier, sc)"></i>
                <div class="supplier-contact-tooltip delete-contact-tooltip ">
                  Delete contact?
                </div>
              </div>
              <div *ngIf="emailMismatch(supplier, sc)" class="supplier-contact-email-mismatch">
                <i class="bi bi-dot"></i>
                <div class="supplier-contact-tooltip email-mismatch-tooltip">
                  Email domain does not match supplier domain
                </div>
              </div>
              <div class="supplier-contact-name">
                <input class="item-text-input sc-input"
                      type="text"
                      (focus)="startEditingSupplierContact($event, supplier, sc, 'name')"
                      (keydown.enter)="inputEnter($event)"
                      (blur)="stopEditingSupplierContact($event, supplier, sc, 'name')"
                      placeholder="Name"
                      [(ngModel)]="sc.name"/>
              </div>
              <div class="supplier-contact-secondary-field"
                    [ngStyle]="{'padding-left': emailMismatch(supplier, sc) ? '6px': ''}"
                >
                <input class="item-text-input sc-input"
                      type="text"
                      (focus)="startEditingSupplierContact($event, supplier, sc, 'email')"
                      (keydown.enter)="inputEnter($event)"
                      (blur)="stopEditingSupplierContact($event, supplier, sc, 'email')"
                      placeholder="Email"
                      [(ngModel)]="sc.email"/>
              </div>
              <div class="supplier-contact-secondary-field">
                <input class="item-text-input sc-input"
                      type="text"
                      (focus)="startEditingSupplierContact($event, supplier, sc, 'phone')"
                      (keydown.enter)="inputEnter($event)"
                      (blur)="stopEditingSupplierContact($event, supplier, sc, 'phone')"
                      placeholder="Phone number"
                      [(ngModel)]="sc.phone"/>
              </div>
              <div class="supplier-contact-secondary-field">
                <input class="item-text-input sc-input"
                      type="text"
                      (focus)="startEditingSupplierContact($event, supplier, sc, 'location')"
                      (keydown.enter)="inputEnter($event)"
                      (blur)="stopEditingSupplierContact($event, supplier, sc, 'location')"
                      placeholder="City"
                      [(ngModel)]="sc.location"/>
              </div>
            </div>
            <div class="add-supplier-contact">
              <i class="bi bi-plus-lg" (click)="addSupplierContact($event, supplier)"
                  style="margin-left: 2px; max-height: 16px; font-size: 16px; position: relative; top: -4px;"></i>
            </div>
          </div>
        </div>
        <div class="long-col-width tracking">
          <div class="tracking-choice">
            <i class="bi bi-bullseye" style="padding-right: 8px"
              [ngStyle]="{'color': colorForTrackSupplier(supplier)}"></i>
              <dropdown-cell-renderer
                [options]="dropdownOptions.credChoices"
                [isAgGrid]="false"
                [data]="findCredChoice(supplier)"
                [cardView]="true"
                [credPage]="true"
                [cardViewWidth]="150"
                [colField]="'credChoices'"
                (statusChanged)="onUpdateDropdown($event, supplier)"
            ></dropdown-cell-renderer>
          </div>
          <div class="supplier-tracking-text">
            SUPPLIER DEFAULT TRACKING
          </div>
          <div class="supplier-tracking-how">
            <i class="tracking-icon"
                [ngClass]="getSupplierIcon(supplier, false, null)"
               [ngStyle]="getSupplierIcon(supplier, true, null, false)">
            </i>
            {{getSupplierTrackingString(supplier)}}
          </div>
          <div class="supplier-tracking-how" style="padding-top: 4px">
            <i class="bi bi-link-45deg tracking-icon" style="font-size: 13px"></i>
            <a *ngIf="supplier.tracking_hostname" [href]="supplier.webtrack_link"
                id="placeholderLink"  target="_blank" rel="noopener noreferrer"
                class="supplier-tracking-link">{{supplier.tracking_hostname}}</a>
          </div>
        </div>
        <div class="default-col-width materlog-tracking">
          <div class="materlog-tracking-text">
            {{setMaterLogTracking(supplier)}}
          </div>
          <i *ngIf="setMaterLogTracking(supplier, true)"
              class="bi bi-exclamation-triangle-fill" style="color: #FC1212"></i>
        </div>
        <div class="default-col-width credential-input-field">
          <input class="item-text-input basic-row-input credential-input-field-text"
                type="text"
                (focus)="startEditingCredential($event, supplier, 'account')"
                (keydown.enter)="inputEnter($event)"
                (blur)="stopEditingCredential($event, supplier, 'account')"
                [(ngModel)]="supplier.credentials.account"/>
        </div>
        <div class="default-col-width credential-input-field">
          <input class="item-text-input basic-row-input credential-input-field-text"
                type="text"
                (focus)="startEditingCredential($event, supplier, 'username')"
                (keydown.enter)="inputEnter($event)"
                (blur)="stopEditingCredential($event, supplier, 'username')"
                [(ngModel)]="supplier.credentials.username"/>
        </div>
        <div class="default-col-width credential-input-field">
          <input class="item-text-input basic-row-input credential-input-field-text"
                type="text"
                (focus)="startEditingCredential($event, supplier, 'password')"
                (keydown.enter)="inputEnter($event)"
                (blur)="stopEditingCredential($event, supplier, 'password')"
                [(ngModel)]="supplier.credentials.password"/>
        </div>
        <div class="share-creds-width no-share-creds">
          <p-checkbox 
            [(ngModel)]="supplier.credentials.no_share_creds"
            name="suppliers"
            [binary]="true"
            (onChange)="shareCredsChange($event, supplier)">
          </p-checkbox>
        </div>
        <div class="delete-supplier">
          <i class="bi bi-trash3-fill" (click)="deleteSupplier($event, supplier)"
              style="font-size: 12px; color: #ddd"></i>
          <div class="delete-supplier-tooltip">
            Remove supplier?
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showCredTooltip" class="change-credential-all">
  <div class="tooltip-close-container">
    <i class="bi bi-x tooltip-close" (click)="closeTooltip()"></i>
  </div>
  <div class="tooltip-share-creds">
    <div class="tooltip-share-creds-text">
      Tracking
    </div>
    <dropdown-cell-renderer
      [options]="dropdownOptions.allCredChoices"
      [isAgGrid]="false"
      [data]="defaultCredChoices"
      [cardView]="true"
      [cardViewWidth]="150"
      [colField]="'credChoices'"
      (statusChanged)="allTrackCreds($event)"
    ></dropdown-cell-renderer>
  </div>
  <div class="tooltip-share-creds" style="padding-top: 6px;">
    <div class="tooltip-share-creds-text">
      Not shared
    </div>
    <dropdown-cell-renderer
      [options]="dropdownOptions.allShareChoices"
      [isAgGrid]="false"
      [data]="defaultShareChoices"
      [cardView]="true"
      [cardViewWidth]="150"
      [colField]="'shareChoices'"
      (statusChanged)="allShareCredsClicked($event)"
    ></dropdown-cell-renderer>
  </div>
  <div class="apply-all-container">
    <div class="apply-all-select" (click)="applyAllSelect()">
      <div class="apply-all-text">
        Apply
      </div>
    </div>
  </div>
</div>


<p-dialog
  *ngIf="addingSupplierContact || addingSupplier"
  [(visible)]="addingSupplierContact || addingSupplier"
  [style]="{ marginTop: '150px', width: '540px' }"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="true"
  [maskStyleClass]="'dialog-mask'"
  [dismissableMask]="true"
  (onHide)="closeSupplierModal()"
  appendTo="body"
>
  <materlog-add-supplier-contact-modal *ngIf="addingSupplierContact"
  [supplierId]="suppierContactSupplier"
  [sidePanelAdd]="true"
  [credPage]="true"
  [contactRoles]="dropdownOptions.roles"
  (supplierContactFormChange)="supplierContactFormChangeEvent($event)"
  ></materlog-add-supplier-contact-modal>

  <materlog-add-supplier-modal *ngIf="addingSupplier"
    (supplierFormChange)="supplierFormChangeEvent($event)"
    [credPage]="true"
  ></materlog-add-supplier-modal>

</p-dialog>
