import { ITableColumn } from '../models/table.model';

export class TableConstants {
  public static readonly jobsTableColumns: ITableColumn[] = [
    { field: 'user_tracking', header: 'Show on tracking', visible: true, editable: true, type: 'toggle', width: 50, is_frozen_column: true, },

    { field: 'index', header: '', visible: true, editable: false, width: 40, is_frozen_column: true},
    { field: 'name', header: 'Job', visible: true, editable: true, width: 140, },
    { field: 'number', header: 'Job#', visible: true, editable: true, width: 80, },
    { field: 'address', header: 'Job address', visible: true, editable: true, width: 140, },
    { field: 'city', header: 'Job city', visible: true, editable: true, width: 100, },
    { field: 'state', header: 'Job state', visible: true, editable: false, type: 'dropdown', width: 100, },
    // { field: 'country', header: 'Country', visible: true, editable: false, type: 'dropdown', width: 100, },
    { field: 'zip', header: 'Zip', visible: true, editable: true, width: 80, },
    { field: 'receivers', header: 'Receiver', visible: true, editable: true, width: 140, },
    // { field: 'receiver.warehouse_name', header: 'Receiver name', visible: true, editable: true, width: 140, },
    { field: 'receiver.address', header: 'Receiver address', visible: true, editable: true, width: 140, },
    { field: 'receiver.city', header: 'Receiver city', visible: true, editable: true, width: 140, },
    { field: 'receiver.state', header: 'Receiver state', visible: true, editable: false, type: 'dropdown', width: 140, },
    // { field: 'receiver.country', header: 'Receiver country', visible: true, editable: false, type: 'dropdown', width: 140, },
    { field: 'receiver.zip', header: 'Receiver zip', visible: true, editable: true, width: 100, },
    { field: 'receiver_contacts', header: 'Receiver contact', visible: true, editable: true, width: 140, },
    // { field: 'receiver_contact.name', header: 'Receiver contact name', visible: true, editable: true, width: 150, },
    { field: 'receiver_contact.email', header: 'Receiver contact email', visible: true, editable: true, width: 150, },
    { field: 'receiver_contact.phone', header: 'Receiver contact phone', visible: true, editable: true, width: 160, },
    { field: 'track_job', header: 'Track job', visible: true, editable: false, type: 'dropdown', width: 140, },
    { field: 'weekly_checkin', header: 'Weekly check in', visible: true, editable: false, type: 'dropdown', width: 140, },
    { field: 'users', header: 'Copied Users', visible: true, editable: false, type: 'multidropdown', width: 140, },
    { field: 'orders', header: 'Orders', visible: true, editable: false, width: 200, },
  ];

  public static jobItemsTableColumns: ITableColumn[] = [
    { field: 'index', header: '', visible: true, editable: false, width: 48, type: 'indexing', order: 1},
    { field: 'job_id', header: 'Job', visible: true, editable: false, width: 140, type: 'dropdown', enableRowGroup: true,order: 2},
    { field: 'supplier_id', header: 'Supplier', visible: true, editable: false, width: 140, enableRowGroup: true, type: 'dropdown' ,order: 3,},
    { field: 'name', header: 'Item', visible: true, editable: true, width: 160, type: 'text', order: 4, },
    { field: 'po_document_number', header: 'PO #', visible: true, editable: false, width: 120, type: 'object',enableRowGroup: true, order: 5, },
    { field: 'po_document_date', header: 'PO date', visible: false, editable: false, width: 100, type: 'dateString', order: 6 },
    { field: 'po_document_attachments', header: 'PO doc', visible: true, editable: false, width: 150, type: 'object', order: 7, },
    { field: 'supplier_documents_numbers', header: 'Order doc #', visible: true, editable: false, width: 150, type: 'object',enableRowGroup: true, order: 8,},
    { field: 'supplier_documents_types', header: 'Order doc type', visible: false, editable: false, width: 150, type: 'object', order: 9,},
    { field: 'supplier_documents_earliest_document_date', header: 'Order date', visible: false, editable: false, width: 150, type: 'dateString',order: 10,},
    { field: 'supplier_documents_attachments', header: 'Order doc', visible: true, editable: true, width: 150, type: 'object',order: 11, },
    { field: 'status', header: 'Status', visible: true, editable: false, width: 120, enableRowGroup: true, type: 'dropdown', order: 12, },
    { field: 'images', header: 'Images', visible: true, editable: true, width: 120, type: 'object', order: 13, },
    { field: 'sku', header: 'SKU', visible: true, editable: true, width: 120,type: 'text',order: 14, },
    { field: 'spec', header: 'Spec', width: 120, visible: false, editable: true, type: 'text',order: 15,},
    { field: 'quantity', header: 'Quantity', visible: true, editable: true, width: 120, type: 'number',order: 16,},
    { field: 'unit_of_measure', header: 'UOM', visible: false, editable: false, type: 'dropdown', width: 120, order: 17,},
    { field: 'description', header: 'Description', visible: false, editable: true, width: 200, type: 'text' ,order: 18,},
    { field: 'link_to_material', header: 'Item URL', visible: false, editable: true, width: 120, type: 'link',order: 19, },
    { field: 'size_item', header: 'Size', width: 120, visible: false, editable: true, type: 'text',order: 20,},
    { field: 'finish', header: 'Finish', width: 120, visible: false, editable: true, type: 'text', order: 21, },
    { field: 'category', header: 'Category', visible: false, editable: false, type: 'dropdown', width: 120, enableRowGroup: true, order: 22,},
    { field: 'room_id', header: 'Room', visible: true, editable: false, width: 120, type: 'dropdown',enableRowGroup: true, order: 23,},
    // { field: 'floor', header: 'Floor', visible: false, editable: false, type: 'dropdown', width: 100, enableRowGroup: true, order: 24,},
    { field: 'delivery_method', header: 'Delivery method', visible: false, editable: false, type: 'dropdown', width: 180,order: 25, },
    { field: 'tracking_info', header: 'Supplier tracking', width: 120, visible: true, editable: false, type: 'ship_link',order: 26, },
    { field: 'shipment_tracking', header: 'Shipment tracking', width: 120, visible: true, editable: false, type: 'ship_link' ,order: 27,},
    { field: 'lead_time', header: 'Lead time', visible: false, editable: true, width: 120,type: 'number', order: 28, },
    { field: 'lead_time_start', header: 'Lead time start', visible: false, editable: true, width: 120,type: 'text', order: 29, },
    { field: 'in_stock_date', header: 'In stock date', visible: true, editable: true, width: 120,  enableRowGroup: true,type: 'dateString', order: 30,},
    { field: 'ship_date', header: 'Ship date', visible: true, editable: true, width: 120, type: 'dateString', enableRowGroup: true,order: 31,},
    { field: 'delivery_date', header: 'Delivery date', visible: true, editable: true, width: 120, type: 'dateString', enableRowGroup: true, order: 32,},
    { field: 'requested_delivery_date', header: 'Requested delivery date', visible: false, editable: true, width: 120, type: 'dateString', enableRowGroup: true,order: 33,},
    { field: 'in_stock_ship_tags', header: 'In stock ship tags', visible: false, enableRowGroup: true, editable: false, type: 'dropdown', width: 120,order: 33, },
   /* { field: 'waiting_for_com', header: 'Waiting for com', visible: false, editable: true, type: 'dropdown', width: 120, },
    { field: 'order_is_a_com', header: 'Order is a com', visible: false, editable: true, type: 'dropdown', width: 120, },*/
    { field: 'item_com_selection', header: 'COM', visible: false, enableRowGroup: true, editable: false, type: 'dropdown', width: 120, order: 34,},
    { field: 'notes', header: 'Notes - internal', visible: true, editable: true, width: 200, type: 'text',order: 36, },
    { field: 'notes_external', header: 'Notes - external', visible: false, editable: true, width: 200, type: 'text', order: 37,},
    { field: 'shipping_to', header: 'Ship to', visible: true, editable: false, type: 'dropdown', width: 120, enableRowGroup: true, order: 38,},
    { field: 'shipping_name', header: 'Ship name', visible: false, editable: true, width: 120,type: 'text',order: 39, },
    { field: 'shipping_address', header: 'Ship address', visible: false, editable: true, width: 120,type: 'text',order: 40, },
    { field: 'shipping_city', header: 'Ship city', visible: false, editable: true, width: 120,type: 'text',order: 41, },
    { field: 'shipping_state', header: 'Ship state', visible: false, editable: false, width: 120, type: 'dropdown', order: 42, },
    { field: 'shipping_zip', header: 'Ship zip', visible: false, editable: true, width: 100,type: 'text',  order: 43, },
    /* { field: 'shipping_country', header: 'Ship country', visible: false, editable: false, width: 150, type: 'dropdown',order: 44, }, */
    { field: 'fob_point', header: 'FOB point', width: 250, visible: false, editable: true, type: 'text',  order: 45, },
    { field: 'total_cost', header: 'Cost - total (Order)', visible: false, editable: true, width: 120, type: 'money',order: 46, },
    { field: 'cost_per_unit', header: 'Cost - per unit', width: 120, visible: false, editable: true, type: 'money',order: 47, },
    { field: 'discount', header: 'Cost - discount (Order)', width: 120, visible: false, editable: true, type: 'text',order: 48,},
    { field: 'shipping_cost', header: 'Cost - shipping (Order)', width: 120, visible: false, editable: true, type: 'money', order: 49, },
    { field: 'sales_tax', header: 'Cost - tax (order)', width: 120, visible: false, editable: true, type: 'money',order: 50, },
    { field: 'payment_sent', header: 'Payment sent', visible: false, editable: true, width: 120, type: 'dateString',order: 51,},
    { field: 'payment_received', header: 'Payment received', visible: false, editable: true, width: 120, type: 'dateString',order: 52,},
    { field: 'payment_method', header: 'Payment method', visible: false, editable: false, width: 120, type: 'dropdown',order: 53,},
    { field: 'last_4_card_digits', header: 'Last 4 cc', visible: false, editable: true, width: 80,type: 'text',order: 54, },
    { field: 'is_reimbursable', header: 'Is reimbursable', visible: false, editable: false, type: 'dropdown', width: 120, order: 55, },
    { field: 'supplier_phone', header: 'Supplier phone', visible: false, editable: true, width: 120,type: 'text',order: 56, },
    { field: 'supplier_email', header: 'Supplier email', visible: false, editable: true, width: 120, type: 'text',order: 57,},
    { field: 'supplier_website', header: 'Supplier website', visible: false, editable: true, width: 120, type: 'link',order: 58, },
    { field: 'supplier_contact_id', header: 'Supplier contact - name', visible: false, editable: false, width: 140,type: 'dropdown', order: 59, },
    { field: 'supplier_contact_title', header: 'Supplier contact - title', visible: false, editable: true, width: 160,type: 'text',order: 60, },
    { field: 'supplier_contact_phone', header: 'Supplier contact - phone', visible: false, editable: true, width: 160,type: 'text' ,order: 61,},
    { field: 'supplier_contact_email', header: 'Supplier contact - email', visible: false, editable: true, width: 160, type: 'text',order: 62,},
    { field: 'supplier_contact_role', header: 'Supplier contact - role', visible: false, editable: false, width: 160, type: 'dropdown', order: 63,},
    { field: 'warehouse_id', header: 'Warehouse item id', width: 120, visible: false, editable: true, type: 'text',order: 64,},
    { field: 'warehouse_rcpt_date', header: 'Warehouse receipt date', width: 120, visible: false, editable: true, type: 'dateString', order: 65, },
    { field: 'pallet_number', header: 'Warehouse pallet #', width: 120, visible: false, editable: true, type: 'text', order: 66, },
    { field: 'custom1t', header: 'Custom (1)', width: 120, visible: false, editable: true, enableRowGroup: true, type: 'text', order: 67,},
    { field: 'custom2t', header: 'Custom (2)', width: 120, visible: false, editable: true, enableRowGroup: true, type: 'text',  order: 68,},
    { field: 'custom3t', header: 'Custom (3)', width: 120, visible: false, editable: true, enableRowGroup: true, type: 'text',order: 69, },
    { field: 'custom5u', header: 'Custom url', width: 120, visible: false, editable: true, enableRowGroup: true, type: 'link',order: 70, },
    { field: 'custom6d', header: 'Custom date', visible: false, editable: true, width: 120, type: 'dateString', enableRowGroup: true, order: 71,},
    { field: 'created_datetime', header: 'Created at', visible: false, editable: false, width: 120, type: 'dateString', order: 72, },
    { field: 'updated_datetime', header: 'Last updated', visible: false, editable: false, width: 120, type: 'dateString',  order: 73,},
    { field: 'supplier_id_2', header: 'Supplier ID', visible: false, editable: false, width: 120, enableRowGroup: true, type: 'text', order: 77, },
    { field: 'order_id', header: 'Order ID', width: 120, visible: false, editable: false, enableRowGroup: true , order: 78,},
    { field: 'id', header: 'Item ID', width: 120, visible: false, editable: false,type: 'text',order: 79, },
    { field: 'track_order', header: 'Track order', visible: false, editable: false, type: 'dropdown', width: 120,enableRowGroup: true,  order: 80, },
    { field: 'next_supplier_user_view', header: 'Next Supplier Checkin', visible: false, editable: false, type: 'stringORdateColumn', width: 180, enableRowGroup: true, order: 81},
    { field: 'dependencies', header: 'Dependencies', visible: false, editable: false, width: 180, enableRowGroup: true, type: 'dependencies', order: 82},
    { field: 'payment_status', header: 'Payment status', visible: false, editable: false, type: 'dropdown', width: 120,enableRowGroup: true,  order: 83, },
  ];

  public static readonly receiversTableColumns: ITableColumn[] = [
    { field: 'expansion_button', header: '', visible: true, editable: false, width: 40, },
    { field: 'index', header: '', visible: true, editable: false, width: 40, },
    { field: 'warehouse_name', header: 'Name', visible: true, editable: true, width: 140, },
    { field: 'email', header: 'Email', visible: true, editable: true, width: 140, },
    { field: 'phone', header: 'Phone', visible: true, editable: true, width: 140, },
    { field: 'address', header: 'Address', visible: true, editable: true, width: 140, },
    { field: 'city', header: 'City', visible: true, editable: true, width: 140, },
    { field: 'state', header: 'State', visible: true, editable: false, type: 'dropdown', width: 140, },
    { field: 'country', header: 'Country', visible: true, editable: false, type: 'dropdown', width: 140, },
    { field: 'zip', header: 'Receiver zip', visible: true, editable: true, width: 100, },
  ];

  public static readonly receiverContactsTableColumns: ITableColumn[] = [
    { field: 'index', header: '', visible: true, editable: false, width: 40, },
    { field: 'name', header: 'Name', visible: true, editable: true, width: 150, },
    { field: 'email', header: 'Email', visible: true, editable: true, width: 150, },
    { field: 'phone', header: 'Phone', visible: true, editable: true, width: 160, },
  ];

  public static readonly suppliersTableColumns: ITableColumn[] = [
    { field: 'expansion_button', header: '', visible: true, editable: false, width: 40, },
    { field: 'index', header: '', visible: true, editable: false, width: 40, },
    { field: 'name', header: 'Name', visible: true, editable: true, width: 140, },
    { field: 'email', header: 'Email', visible: true, editable: true, width: 140, },
    { field: 'phone', header: 'Phone', visible: true, editable: true, width: 140, },
    { field: 'website', header: 'Website', visible: true, editable: true, width: 140, },
  ];

  public static readonly supplierCredentialsTableColumns: ITableColumn[] = [


    { field: 'supplier', header: 'Supplier', visible: true, editable: true, width: 140, enableRowGroup: true,},
    { field: 'group_name', header: 'Name', visible: true, editable: true, width: 140,enableRowGroup: true, },
    { field: 'credential_type', header: 'Credential Type', visible: true, editable: true, width: 140,enableRowGroup: true,},
    { field: 'track_supplier', header: 'Track Supplier', visible: true, editable: false, width: 140,enableRowGroup: true, type: 'dropdown'},
    { field: 'value', header: 'Value', visible: true, editable: true, width: 140, },
    { field: 'note', header: 'Note', visible: true, editable: true, width: 140, },
    { field: 'created_datetime', header: 'Added', visible: true, editable: false, width: 140, },
    { field: 'updated_datetime', header: 'Updated', visible: true, editable: false, width: 140, },


  ];

  public static readonly normalizedSupplierCredentialsTableColumns: ITableColumn[] = [
    { field: 'track_supplier', header: 'Track Supplier', visible: true, editable: false, width: 100, type: 'dropdown'},
    { field: 'supplier', header: 'Supplier', visible: true, editable: false, width: 100, },
    { field: 'username', header: 'Username', visible: true, editable: true, width: 100, },
    { field: 'password', header: 'Password', visible: true, editable: true, width: 100, },
    { field: 'account', header: 'Account', visible: true, editable: true, width: 100, },
    { field: 'no_share_creds', header: 'Choose not to share credentials', visible: false, editable: true, width: 200, },
    { field: 'trackable', header: 'Can Materlog track your orders?', visible: false, editable: true, width: 200, },
  ];


  public static readonly supplierContactsTableColumns: ITableColumn[] = [
    { field: 'index', header: '', visible: true, editable: false, width: 40, },
    { field: 'first_name', header: 'First name', visible: true, editable: true, width: 150, },
    { field: 'last_name', header: 'Last name', visible: true, editable: true, width: 150, },
    { field: 'email', header: 'Email', visible: true, editable: true, width: 150, },
    { field: 'phone', header: 'Phone', visible: true, editable: true, width: 160, },
    { field: 'role', header: 'Role', visible: true, editable: false, type: 'dropdown', width: 160, },
    { field: 'title', header: 'Title', visible: true, editable: true, width: 160, },
  ];

  public static readonly customColumnsLayout = [
    "index",
    "status",
    "po_document_number",
    "po_document_date",
    "po_document_attachments",
    "supplier_documents_numbers",
    "supplier_documents_attachments",
    "images",
    "supplier_id",
    "supplier_contact_id",
    "room_id",
    "link_to_material",
    "supplier_website",
    "tracking_info"
  ];

  public static readonly TextNonNull = [
    "name",
  ]

  public static readonly SubItemFields = [
    "quantity", "status", "tracking_info", "shipment_tracking",
    "ship_date", "delivery_date", "in_stock_date", "warehouse_rcpt_date",
    "warehouse_id", "pallet_number",    
  ]

  public static readonly dropDownEditable = [
    'unit_of_measure', 'category', 'room_id', 'delivery_method',
    'in_stock_ship_tags', 'item_com_selection', 'shipping_to',
    'shipping_state', 'payment_method', 'is_reimbursable', 'track_order',
    'payment_status'
  ]


  public static readonly dateColumnLayout: string[] =  [
    "po_document_date",
    "updated_datetime",
    "delivery_date",
    "created_datetime",
    "supplier_documents_earliest_document_date",
    "updated_datetime",
    "custom6d",
    "created_datetime",
    "in_stock_date",
    "ship_date",
    "requested_delivery_date",
    "payment_sent",
    "payment_received",
    "warehouse_rcpt_date"
  ];

  public static readonly optionsColumnsLayout = [
    "state","country","receiver.state","receiver.country",
    "job_id","unit_of_measure","category","floor","in_stock_ship_tags",
    "item_com_selection",
    "shipping_to","shipping_state","shipping_country",
    "payment_method","is_reimbursable","supplier_contact_role","track_order",
    "status", 'payment_status',
    "delivery_method",
    "supplier_id","supplier_documents_types",
    "room_id", 'po_document_number',
    "order_id","supplier_id_2","id"

  ];


  public static readonly numberColumnsLayout = [
    "index",
    "quantity",
    "lead_time",
    "total_cost",
    "cost_per_unit",
    "shipping_cost",
    "sales_tax"
  ];


  public static readonly wholenumberColumns = [
    "index",
    "lead_time",
  ];

  public static readonly optionsReverseMappingOutput = [
    ''
  ]
// 'supplier_documents_types'

  public static readonly limitedSelect = [
    'supplier_documents_types',
    'po_document_number'
  ]

  public static readonly columnsWithoutBackendDataDBSupport = [
    'index'
  ]

  public static readonly optionsSpecialViewOutput = [
    "is_reimbursable" ,
    "category",
    "floor",
    "delivery_method",
    "in_stock_ship_tags", "shipping_to" ,"payment_method" ,"shipping_country",
    "item_com_selection",
    "unit_of_measure" ,"supplier_contact_role" ,
    "track_order" ,"shipping_state", 'payment_status'
]

  public static readonly excludedFieldsForOrderUpdate = ["status_color", "room_id", "updated_datetime", "id", "po_document_date","supplier_documents_types"];
  public static readonly excludedFieldsForItemUpdate = ["order_id", "id"];
  public static readonly fieldsForOrderUpdate = [
    "job_id",
    "supplier_id",
    "supplier_contact_id",
    "shipping_to",
    "shipping_name",
    "shipping_address",
    "shipping_city",
    "shipping_state",
    "shipping_zip",
    "shipping_country",
    "total_cost",
    "payment_method",
    "last_4_card_digits",
    "is_reimbursable",
    "unit_of_measure",
    "next_supplier_follow_up_date",
    "next_supplier_follow_up_time",
    "last_email_sent",
    "next_supplier_user_view",
    "next_supplier_follow_up_note",
    "track_order",
    "payment_status",
  ];
  public static readonly fieldsForSupplierUpdate = ["supplier_id", "supplier_phone", "supplier_email", "supplier_website"];
  public static readonly fieldsForSupplierContactUpdate = [
    "supplier_contact_id",
    "supplier_contact_title",
    "supplier_contact_phone",
    "supplier_contact_email",
    "supplier_contact_role"
  ];
  public static readonly fieldsForHistoryUpdate = [
    "status",
    "delivery_method",
    "in_stock_date",
    "ship_date",
    "delivery_date",
    "in_stock_ship_tags",
    "supplier_id"
  ];


  public static readonly cellWhichNeedReloadSinceAffectOthers = [
    "shipping_to", "shipping_name", "supplier_contact_id",
    "supplier_id", "is_reimbursable" ,  "shipping_address" , "shipping_city" ,
    "shipping_state", "shipping_country",
    "job_id" , "payment_method", "supplier_contact_role",
    "po_document_number", "supplier_documents_numbers",
    "track_order", 'payment_status'
  ];

  public static readonly fieldsWithAddOption: string[] = ["room_id", "supplier_contact_id"];
  public static readonly row_small = 30;
  public static readonly row_medium = 50;
  public static readonly row_large = 100;

  public static readonly fastDropDownOptions = [
    "category",
    "country",
    "delivery_method",
    "floor",
    "in_stock_ship_tags",
    "is_reimbursable",
    "item_com_selection",
    "job_id",
    "order_is_a_com",
    "payment_method",
    "receiver.country",
    "receiver.state",
    "role",
    "room_id",
    "shipping_country",
    "shipping_state",
    "shipping_to",
    "state",
    "status",
    "supplier_contact_id",
    "supplier_contact_role",
    "track_job",
    "track_order",
    "payment_status",
    "track_supplier",
    "unit_of_measure",
    "weekly_checkin",
    "status_colors" ,
    "supplier_id",
  ]

}
