<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="auth-container sm:mx-auto sm:w-full sm:max-w-sm">
  <div class="logo" (click)="onNavigateHome()" style="cursor: pointer">
    <img src="/assets/img/materlog_logo.svg" alt="materlog-logo" />
  </div>

  <div class="form">
    <div class="title">Password reset</div>
    <form [formGroup]="recoverPasswordForm" class="form-group" *ngIf="!formSubmitted">
      <div class="textfield">
        <label class="textfield__label" for="password">Password</label>
        <input
          [ngClass]="{
            'textfield--error':
              recoverPasswordForm.controls.passwordRecover.invalid &&
              recoverPasswordForm.controls.passwordRecover.touched &&
              isSubmitted
          }"
          type="password"
          id="passwordRecover"
          formControlName="passwordRecover"
          class="form-controls"
          placeholder="********"
        />
      </div>

      <div class="textfield">
        <label class="textfield__label" for="passwordRecover">Confirm password</label>
        <input
          [ngClass]="{
            'textfield--error':
              recoverPasswordForm.controls.passwordConfirmation.invalid &&
              recoverPasswordForm.controls.passwordConfirmation.touched &&
              isSubmitted
          }"
          type="password"
          id="passwordConfirmation"
          formControlName="passwordConfirmation"
          class="form-controls"
          placeholder="********"
        />
        <div
          class="textfield__error-message"
          *ngIf="
            (recoverPasswordForm.controls.passwordConfirmation.invalid &&
              recoverPasswordForm.controls.passwordConfirmation.touched &&
              isSubmitted) ||
            (recoverPasswordForm.controls.passwordRecover.invalid &&
              recoverPasswordForm.controls.passwordRecover.touched &&
              isSubmitted)
          "
        >
          Password must be at least 8 characters, including 1 special character
        </div>
      </div>

      <div class="textfield__error-message" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="textfield__error-message" *ngIf="!passwordMatch && isSubmitted && recoverPasswordForm.valid">
        Passwords don't match
      </div>
      <button
        pButton
        type="submit"
        label="Reset password"
        class="button-primary p-button-raised"
        (click)="onSubmit()"
      ></button>
    </form>

    <div *ngIf="formSubmitted">
      <div class="subtitle">Your MaterLog password has been successfully reset.</div>
      <div class="in-line">
        <div class="link link--recover" (click)="onNavigateToSignIn()">Sign in</div>
        <div>to your account.</div>
      </div>
    </div>
  </div>
</div>
</div>
