import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { select, Store } from '@ngrx/store';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { UserService } from '../../services/user.service';
import { generateRandomString } from 'src/app/common/utils/general';



@Component({
  selector: 'materlog-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent extends OnDestroyMixin() implements OnInit {
  @Input() dropdownOptions: any;
  @Input() allMailData: any;

  @ViewChild('userSettingsContainer') userSettingsContainer!: ElementRef<HTMLDivElement>;

  isLoading: boolean = true;
  userData: any = {};
  userName: any = '';
  password: any = '';
  defaultName: string = 'Your name';
  hasName: boolean = false;

  editingPassword: boolean = false;
  editingName: boolean = false;

  jobsAndOrders = 'Jobs and Orders';
  suppliers = 'Suppliers';
  comments = 'Comments';

  passwordSuccess: boolean = false;
  isPasswordLoading: boolean = false;
  isIncorrectPassword: any = '';
  oldPassword: string = '';
  newPassword1: string = '';
  newPassword2: string = '';

  jobs_weekly_digest = 'Weekly digest of order updates';
  jobs_daily_digest = 'Daily digest of date and status changes';
  jobs_new = 'New job added';
  jobs_complete = 'Job complete';
  jobs_critical = 'Need information or incorrect information';

  suppliers_new = 'New supplier added';
  suppliers_critical = 'Need information or incorrect information';
  subtext = 'Critical for timely order monitoring and management';

  comments_made = 'Comment made on job you are watching';

  settings: any = [
    {
      name: this.jobsAndOrders,
      options: [
        {
          name: this.jobs_weekly_digest,
          email: true,
          email_default: true,
        },
        {
          name: this.jobs_daily_digest,
          email: true,
          email_default: false,
        },
        {
          name: this.jobs_new,
          app: true,
          email: true,
          app_default: true,
          // email_default: true,
        },
        {
          name: this.jobs_complete,
          app: true,
          email: true,
          app_default: true,
          email_default: true,
        },
        {
          name: this.jobs_critical,
          app: true,
          // email: true,
          app_default: true,
          // email_default: true,
          subtext: this.subtext,
        },
      ]
    },
    {
      name: this.suppliers,
      options: [
        {
          name: this.suppliers_new,
          app: true,
          // email: true,
          app_default: true,
        },
        {
          name: this.suppliers_critical,
          app: true,
          // email: true,
          app_default: true,
          // email_default: true,
          subtext: this.subtext
        },
      ]
    },
    {
      name: this.comments,
      options: [
        {
          name: this.comments_made,
          app: true,
          // email: true,
          app_default: true,
          // email_default: true,
        },
      ]
    },
  ]

  constructor(
    private store: Store<IApplicationState>,
    private userService: UserService,
    private dashboardActions: DashboardActions) {
    super();
  }

  logging: any = undefined;
  ngOnInit(): void {
    this.generateItems();
    this.getUserData();
    (window as any)['user'] = this;
  }

  getUserData() {
    this.userService.getUserProfile().subscribe((result: any) => {
      this.initUserData(result);
    })
  }

  initUserData(result: any) {
    this.userData = result;
    let username = [this.userData.first_name, this.userData.last_name];
    username = username.filter((u: any) => u);
    this.userName = username.join(' ');
    this.hasName = Boolean(username.length);
    this.setUserData();
    this.isLoading = false;
  }

  setUserData(write: boolean = false) {
    let output: any = {};
    this.settings.forEach((setting: any) => {
      setting.options.forEach((option: any) => {
        if (setting.name == this.jobsAndOrders) {
          if (option.name == this.jobs_weekly_digest) {
            if (write) {
              output.email_weekly_digest_order_updates = option.email_data;
            } else {
              option.email_data = this.userData.email_weekly_digest_order_updates;
            }
          } else if (option.name == this.jobs_daily_digest) {
            if (write) {
              output.email_daily_digest_item_updates = option.email_data;
            } else {
              option.email_data = this.userData.email_daily_digest_item_updates;
            }
          } else if (option.name == this.jobs_new) {
            if (write) {
              output.email_new_job_added = option.email_data;
              output.in_app_new_job_added = option.app_data;
            } else {
              option.email_data = this.userData.email_new_job_added;
              option.app_data = this.userData.in_app_new_job_added;
            }
          } else if (option.name == this.jobs_complete) {
            if (write) {
              output.email_job_complete = option.email_data;
              output.in_app_job_complete = option.app_data;
            } else {
              option.email_data = this.userData.email_job_complete;
              option.app_data = this.userData.in_app_job_complete;
            }
          } else if (option.name == this.jobs_critical) {
            if (write) {
              output.email_critical_order_info = option.email_data;
              output.in_app_critical_order_info = option.app_data;
            } else {
              option.email_data = this.userData.email_critical_order_info;
              option.app_data = this.userData.in_app_critical_order_info;
            }
          }
        } else if (setting.name == this.suppliers) {
          if (option.name == this.suppliers_new) {
            if (write) {
              output.email_new_supplier = option.email_data;
              output.in_app_new_supplier = option.app_data;
            } else {
              option.email_data = this.userData.email_new_supplier;
              option.app_data = this.userData.in_app_new_supplier;
            }
          } else if (option.name == this.suppliers_critical) {
            if (write) {
              output.email_critical_supplier_info = option.email_data;
              output.in_app_critical_supplier_info = option.app_data;
            } else {
              option.email_data = this.userData.email_critical_supplier_info;
              option.app_data = this.userData.in_app_critical_supplier_info;
            }

          }
        } else if (setting.name == this.comments) {
          if (option.name == this.comments_made) {
            if (write) {
              output.email_comment_made = option.email_data;
              output.in_app_comment_made = option.app_data;
            } else {
              option.email_data = this.userData.email_comment_made;
              option.app_data = this.userData.in_app_comment_made;
            }
          }
        }
      })
    });
    return output;
  }

  editPasswordClicked() {
    this.editingPassword = true;
  }

  userInputClicked(username: boolean = true) {
    let value: string = '';
    if (username) {
      value = '[id=user-settings-username]';
      this.editingName = true;
    } else {
      value = '[id=user-settings-password]';
    }
    setTimeout(() => {
      let el: any = this.userSettingsContainer?.nativeElement?.querySelector(value);
      if (el) {
        el.focus();
      }
    }, 30);
  }


  updateUserName(event: any) {
    this.editingName = false;
    let names = this.userName.trim().split(' ');
    let first_name = names[0];
    let last_name = names.slice(1,).join(' ');
    let data = {first_name: first_name, last_name: last_name};
    this.userService.updateUserProfile({payload: data}).subscribe(() => {});
  }

  restoreDefaults() {
    let data = {restore: true};
    this.userService.updateUserProfile({payload: data}).subscribe((result: any) => {
      this.initUserData(result);
    });
  }

  updateUserOptions(event: any) {
    let data = this.setUserData(true);
    this.userService.updateUserProfile({payload: data}).subscribe(() => {});
  }

  get getName() {
    return this.hasName ? this.userName : this.defaultName;
  }

  skeletonItems: any[] = [];
  generateItems() {
    for (let i = 0; i < 15; i++) {
      const randomWidth = Math.floor(Math.random() * 25) + 5;
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }
  }

  inputEnter(event: any) {
    event.target.blur();
  }

  onFileSelected(event: any) {
    const input = event.target as HTMLInputElement;
    const formData = new FormData();
    let newfile = false;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        let cur = input.files[i];
        formData.append('file', cur, cur.name);
        newfile = true;
      }
    }
    if (newfile) {
      this.userService.updateUserProfilePicture(formData).subscribe((result: any) => {
        if (result?.link) {
          this.userData.image_profile = result.link;
        }
      })
    }
    event.target.value = '';
  }

  uploadFile(event: any) {
    event.stopPropagation();
  }

  isPasswordDisabled(which: any) {
    if (which == 0) {
      return true;
    } else if (which == 1) {
      return !Boolean(this.oldPassword);
    } else if (which == 2) {
      return !Boolean(this.oldPassword && this.newPassword1);
    }
    return false;
  }

  get canSubmitPassword() {
    return this.oldPassword && this.newPassword1 && this.newPassword2 && this.passwordAcceptable;

  }

  cancelPassword() {
    this.editingPassword = false;
    this.isPasswordLoading = false;
    this.oldPassword = '';
    this.newPassword1 = '';
    this.newPassword2 = '';
  }

  resetPassword() {
    if (!this.canSubmitPassword) {
      return;
    }
    this.isPasswordLoading = true;
    let payload = {
      old_password: this.oldPassword,
      new_password1: this.newPassword1,
      new_password2: this.newPassword2,
    }
    this.userService.updatePassword({payload: payload}).subscribe((result: any) => {
      this.isPasswordLoading = false;
      if (result.error) {
        this.oldPassword = '';
        this.newPassword1 = '';
        this.newPassword2 = '';
        this.setPasswordErrorString(result.error);
      } else if (result.success) {
        this.passwordSuccess = true;
        setTimeout(() => {
          this.editingPassword = false;
          this.passwordSuccess = false;
        }, 1000);
      }
    })
  }

  setPasswordErrorString(err: any) {
    this.isIncorrectPassword = err;
    setTimeout(() => {
      this.isIncorrectPassword = '';
    }, 4000);
  }

  get passwordAcceptable() {
    return this.newPassword1 == this.newPassword2 &&
            this.newPassword1.length >= 8 &&
            this.newPassword1.length <= 30 &&
            this.newPassword2.length >= 8 &&
            this.newPassword2.length <= 30;
  }

  get getPasswordError() {
    if (this.isIncorrectPassword && !this.oldPassword &&
          !this.newPassword1 && !this.newPassword2) {
      return this.isIncorrectPassword;
    }
    if (this.newPassword1 && this.newPassword2 && this.newPassword1 != this.newPassword2) {
      return 'New passwords do not match';
    }
    if (this.newPassword1 && this.newPassword1.length < 8) {
      return 'Minimum password length is 8';
    } 
    if (this.newPassword1 && this.newPassword1.length > 30) {
      return 'Maximum password length is 30';
    } 
    return '';
  }
}
