import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { TableConstants } from './../../../common/constants/table.constants';
import { ITableColumn } from 'src/app/common/models/table.model';
import { DashboardRoutes } from 'src/app/dashboard/enums/dashboard-routes.enum';
import { ReceiversService } from '../../services/receivers.service';
import { DashboardService } from '../../services/dashboard.service';
import { JobsService } from '../../services/job.service';
import { UserService } from 'src/app/user-settings/services/user.service';

@Component({
  selector: 'materlog-view-jobs',
  templateUrl: './view-jobs2.component.html',
  styleUrls: [
    './view-jobs.component.css',
    '../../../suppliers/components/suppliers-credentials-page/suppliers-credentials-page-new.component.css'
  ],
})
export class ViewJobsComponent extends OnDestroyMixin() implements OnInit {
  @ViewChild('materlogTable') materlogTable: Table | any;
  @Input() dropdownOptions: any;
  @Input() allMailData: any;

  @Input() notificationsView: boolean = false;
  @Input() jobId: any = undefined;
  @Input() panelOpen: boolean = true;

  temporaryReceiverList: any;
  temporaryReceiverValue: any;
  DashboardRoutes = DashboardRoutes;

  isLoading: boolean = true;

  allJobs: any = [];
  archivedJobs: any = [];
  filteredJobs: any = [];
  showAllJobs: boolean = true;
  searchText: string = '';
  allUsers: any = [];
  allReceivers: any = [];
  userId: any = '';

  jobAddingReceiver: any = undefined;

  logging: any = undefined;
  showNewJob: boolean = false;
  showNewReceiver: boolean = false;

  constructor(
    public router: Router,
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private messageService: MessageService,
    private recevierService: ReceiversService,
    private dashboarddService: DashboardService,
    private jobsService: JobsService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    (window as any)['jj'] = this;

  }

  ngOnInit(): void {
    this.generateItems();
    this.getAllJobs();
    (window as any)['jj'] = this;
  }

  ngOnChanges(changes: any) {
    if (changes?.inputDropdownOptions?.currentValue) {
      this.dropdownOptions = {...changes.inputDropdownOptions.currentValue, ...this.dropdownOptions};
    }
  }

  skeletonItems: any[] = [];
  generateItems() {
    let skels = this.notificationsView ? 3 : 30;
    for (let i = 0; i < skels; i++) {
      const randomWidth = Math.floor(Math.random() * 25) + 5;
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }
  }

  setWeeklyCheckin(jobsList: any) {
    jobsList.forEach((j: any) => {
      if (j.job.weekly_checkin == 5) { // None
        j.job.weekly_checkin = 1; // Tuesday
      }
      j.job.track_job = Boolean(j.job.track_job);
    })
  }

  setJobsLists(jobsList: any) {
    this.allJobs = jobsList.filter((j: any) => !j?.job?.is_archived);
    this.archivedJobs = jobsList.filter((j: any) => j?.job?.is_archived);

    this.allJobs.forEach((j: any) => {
      let found = j?.trackers?.find((w: any) => w.user_id == this.userId);
      j.job.user_tracking = Boolean(found);
    })

    if (this.allJobs.length > 1) {
      this.allJobs.sort((a: any, b: any) => a.job.name.localeCompare(b.job.name));
    }

    if (this.archivedJobs.length > 1) {
      this.archivedJobs.sort((a: any, b: any) => a.job.name.localeCompare(b.job.name));
    }
  }

  createReceiversList(jobsList: any) {
    let rrs = jobsList
      .filter((j: any) => j.receiver)
      .map((r: any) => r.receiver)
      .reduce((acc: any, current: any) => {
        if (!acc.some((item: any) => item.id === current.id)) {
          acc.push(current);
        }
        return acc;
      }, [])
      .map((r: any) => ({id: r.id, value: r.warehouse_name}));
    
    if (rrs.length > 1) {
      rrs.sort((a: any, b: any) => a.value.localeCompare(b.value));
    }
    rrs.push({id: -1, value: 'Create receiver'});
    this.allReceivers = rrs;
  }

  getAllJobs() {
    this.jobsService.getJobList({payload: {}}).subscribe((jobs: any) => {
      this.isLoading = false;
      this.allUsers = jobs?.org_users || [];
      this.userId = jobs.user_id;
      let jobsRetrieved = jobs?.results || []
      let jobsList = [...jobsRetrieved];
      if (this.jobId) {
        jobsList = jobsList.filter((j: any) => j.job.id == this.jobId);
      }
      this.createReceiversList(jobsList);
      this.setWeeklyCheckin(jobsList);
      this.setJobsLists(jobsList);
      this.filteredJobs = this.allJobs;
      this.globalReceiverUniqueObject();
    });
  }

  addJob() {
    this.showNewJob = true;
  }

  jobBeenAdded() {
    this.showNewJob = false;
    this.getAllJobs();
  }

  get allDataRetrieved() {
    return !this.isLoading 
            && this.dropdownOptions?.state;
  }

  doShowJobs(which: boolean) {
    this.showAllJobs = which;
    this.onSearchChange();
  }

  resortArchived(job: any) {
    if (this.showAllJobs) {
      this.allJobs = this.allJobs.filter((j: any) => j.job.id != job.job.id);
      this.archivedJobs.push(job);
      this.archivedJobs.sort((a: any, b: any) => a.job.name.localeCompare(b.job.name));
    } else {
      this.archivedJobs = this.archivedJobs.filter((j: any) => j.job.id != job.job.id);
      this.allJobs.push(job);
      this.allJobs.sort((a: any, b: any) => a.job.name.localeCompare(b.job.name));
    }
    this.onSearchChange();
  }

  doUpdateJob(event: any, job: any, field: any) {
    let value = job.job[field];
    if (field == 'track_job') {
      value = value ? 1 : 0;
    }
    let payload = {id: job.job.id, [field]: value}
    this.jobsService.updateJob({payload: payload}).subscribe(() => {
      if (field == 'is_archived') {
        this.resortArchived(job);
      }
      if (field == 'user_tracking' || field == 'is_archived') {
        this.dashboardActions.requestActiveJobs();
      }
    });
  }

  doUpdateReceiverName(event: any, receiver: any, field: any) {
    receiver.editing = false;
    let found = this.allReceivers.find((r: any) => r.id == receiver.id);
    if (found) {
      found.value = receiver.warehouse_name;
    }
    this.doUpdateReceiver(event, receiver, field);

  }

  globalReceiverUpdate(receiver: any) {
    let vals = [this.allJobs, this.archivedJobs];
    vals.forEach((v: any) => {
      v.forEach((j: any) => {
        if (j.receiver?.id == receiver.id) {
          j.receiver = {...receiver};
          j.receiver.receiver_contacts = j.receiver.receiver_contacts
            ?.map((r: any) => ({...r})) || []; 
        }
      })
    });
    this.cdr.detectChanges();
  }

  globalReceiverUniqueObject() {
    let vals = [this.allJobs, this.archivedJobs];
    vals.forEach((v: any) => {
      v.forEach((j: any) => {
        if (j.receiver?.id) {
          j.receiver = {...j.receiver};
          if (j.receiver?.receiver_contacts?.length) {
            j.receiver.receiver_contacts = j.receiver.receiver_contacts
              ?.map((r: any) => ({...r})) || [];
          }
        }
      })
    });
    this.cdr.detectChanges();
  }


  curEvent: any = undefined;
  doUpdateReceiver(event: any, receiver: any, field: any) {
    this.curEvent = event;
    let value = receiver[field];
    if (field == 'email' && value && !this.isValidEmail(value)) {
      receiver.invalid_email = true;
      receiver.email = '';
      setTimeout(() => {
        event.target.value = '';
        event.target.focus();
      }, 20)

      setTimeout(() => {
        receiver.invalid_email = false;
      }, 1500)
      return;
    }
    let payload = {id: receiver.id, [field]: value}
    this.jobsService.updateReceiver({payload: payload}).subscribe(() => {
      if (field == 'user_tracking') {
        this.dashboardActions.requestActiveJobs();
      }
    });
    this.globalReceiverUpdate(receiver);
  }


  handleJobWatchersChange(event: any, job: any, field: any) {
    let watchers = [...event.event.value];
    let users = watchers.map((v: any) => ({users_id: v.id}));
    let payload = {id: job.job.id, [field]: users};
    job.watchers = watchers;
    this.cdr.detectChanges();
    this.jobsService.updateJob({payload: payload}).subscribe(() => {});
  }

  onSearchChange() {
    let search = this.searchText.toLowerCase();
    if (!search) {
      this.filteredJobs = this.showAllJobs ? this.allJobs: this.archivedJobs;
      return;
    }
    let cur: any = [];
    let job_fields = ['name', 'city', 'address', 'number'];
    let recv_fields = ['warehouse_name', 'address', 'city'];
    this.allJobs.forEach((j: any) => {
      if (this.showAllJobs && j.job.is_archived) {
        return;
      } else if (!this.showAllJobs && !j.job.is_archived) {
        return;
      }
      let text = '';
      job_fields.forEach((f: any) => {
        text += j.job[f] ? j.job[f]: '';
      })

      recv_fields.forEach((f: any) => {
        if (j.receiver) {
          text += j.receiver[f] ? j.receiver[f]: '';
        }
      })
      text = text.toLowerCase();
      if (text.includes(search)) {
        cur.push(j);
      }
    });
    this.filteredJobs = cur;
  }

  findState(data: any) {
    return this.dropdownOptions?.state?.find(
      (state: any) => state.id == data?.state_id
    )
  }

  findWeeklyCheckin(data: any) {
    return this.dropdownOptions?.weekly_checkin?.find(
      (wc: any) => wc.id == data?.weekly_checkin
    )
  }

  onUpdateDropdown(event: any, value: any, field: any) {
    let payload = {id: value.id, [field]: event.event.value.id}
    this.jobsService.updateJob({payload: payload}).subscribe(() => {});
  }

  onUpdateDropdownReceiver(event: any, receiver: any, field: any) {
    let payload = {id: receiver.id, [field]: event.event.value.id}
    this.jobsService.updateReceiver({payload: payload}).subscribe(() => {});
  }

  handleReceiverChange(event: any, job: any) {
    let rcv_id = event.event.value.id;
    if (rcv_id == -1) {
      setTimeout(() => {
        job.receiver = null;
        this.cdr.detectChanges();
      }, 50);
      this.doUpdateJob(event, job, 'receiver');
      this.showNewReceiver = true;
      this.jobAddingReceiver = job.job.id;
    } else {
      let vals = [this.allJobs, this.archivedJobs];
      let found = false
      vals.forEach((v: any) => {
        v.forEach((j: any) => {
          if (!found && j.receiver?.id == rcv_id) {
            job.receiver = {...j.receiver};
            job.receiver.receiver_contacts = j.receiver.receiver_contacts
                ?.map((r: any) => ({...r})) || [];
            found = true;
            this.setReceiverOnJob(job.job, rcv_id);
          }
        })
      });
      this.cdr.detectChanges();
    }
  }

  setReceiverOnJob(job: any, receiver_id: any) {
    let payload = {id: job.id, 'receiver_id': receiver_id}
    this.jobsService.updateJob({payload: payload}).subscribe(() => {});
  }

  recreateReceivers(new_receiver: any) {
    let rcvs = this.allReceivers.slice(0, -1);
    rcvs.push({id: new_receiver.id, value: new_receiver.warehouse_name});
    if (rcvs.length > 1) {
      rcvs.sort((a: any, b: any) => a.value.localeCompare(b.value));
    }
    rcvs.push({id: -1, value: 'Create receiver'});
    this.allReceivers = rcvs;
  }

  receiverContactBeenAdded(event: any) {
    let outer = this;
    var pollInterval = setInterval(function() {
      if (!outer.showNewReceiver) {
        clearInterval(pollInterval);
        outer._receiverContactBeenAdded(event);
      }
    }, 20);
  }

  _receiverContactBeenAdded(event: any) {
    let recv_id = event.receiver;
    let contact = event.contact?.data;
    if (!contact) return;
    let contact_id = contact.id;
    let vals = [this.allJobs, this.archivedJobs];
    vals.forEach((v: any) => {
      v.forEach((j: any) => {
        if (j.receiver?.id == recv_id) {
          if (!j.receiver?.receiver_contacts) {
            j.receiver.receiver_contacts = [];
          }
          if (j.receiver?.receiver_contacts?.find((c: any) => c.id == contact_id)) {
            return;
          }
          j.receiver.receiver_contacts.push({...contact});
        }
      })
    });
    this.cdr.detectChanges();
  }

  receiverBeenAdded(event: any) {
    let recv_id = event?.data?.id;
    if (this.showNewReceiver && recv_id && this.jobAddingReceiver) {
      let curJob = this.jobAddingReceiver;
      this.jobAddingReceiver = undefined;
      if (event.data) {
        this.recreateReceivers(event.data);
      }
      setTimeout(() => {
        this.showNewReceiver = false;
      }, 300)
      let vals = [this.allJobs, this.archivedJobs];
      vals.forEach((v: any) => {
        v.forEach((j: any) => {
          if (j.job?.id == curJob) {
            j.receiver = {...event.data};
            j.receiver.receiver_contacts = j.receiver.receiver_contacts
              ?.map((r: any) => ({...r})) || [];
            this.setReceiverOnJob(j.job, recv_id);
          }
        })
      });
    }

  }

  curTarget = undefined;
  editReceiverName(event: any, receiver: any) {
    this.curTarget = event?.target?.parentElement?.parentElement as any;
    let curTarget = this.curTarget;
    receiver.editing = true;
    setTimeout(() => {
      if (curTarget) {
        (curTarget as any).querySelector('.sc-input')?.focus();
      }
    }, 40);
  }

  deleteRecieverContact(receiver: any, contact: any) {
    this.jobsService.deleteReceiverContact(contact.id).subscribe(() => {})
    receiver.receiver_contacts = receiver.receiver_contacts.filter(
      (rc: any) => rc.id != contact.id
    )
    this.globalReceiverUpdate(receiver);
  }

  stopEditingReceiverContact(event: any, receiver: any, contact: any, field: any) {
    let value = contact[field];
    if (field == 'email' && value && !this.isValidEmail(value)) {
      contact.invalid_email = true;
      contact.email = '';
      setTimeout(() => {
        event.target.value = '';
        event.target.focus();
      }, 20)

      setTimeout(() => {
        contact.invalid_email = false;
      }, 1500)
      return;
    }
    let payload = {id: contact.id, [field]: value};
    this.jobsService.updateReceiverContact({payload: payload}).subscribe(() => {});
    this.globalReceiverUpdate(receiver);
  }

  addReceiverContact(event: any, receiver: any) {
    if (!receiver.receiver_contacts) {
      receiver.receiver_contacts = [];
    }
    let rstring = this.generateRandomString();
    receiver.receiver_contacts.push({
      id: rstring,
      temp: true,
      name: '',
      email: '',
      phone: '',
    });
    this.jobsService.addReceiverContact({receiver_id: receiver.id})
      .subscribe((result: any) => {
        let found = receiver.receiver_contacts.find((rc: any) => rc.id == rstring);
        if (found) {
          delete found.temp;
          found.id = result.id;
          this.globalReceiverUpdate(receiver);
        } else {
          this.deleteRecieverContact(receiver, result);
        }
      })

  }

  jobColumns(): any {
    let defaultCols = [
      'JOB',
      'JOB #',
      'ADDRESS',
      'TRACK JOB',
      'WEEKLY CHECKIN',
      'EMAILED USERS',
      'RECEIVER',
      'RECEIVER CONTACTS',
    ];
    if (!this.showAllJobs) {
      defaultCols.unshift('ARCHIVED');
      return defaultCols;
    }
    defaultCols.unshift('SHOW ON TRACKING');
    defaultCols.push('ARCHIVE JOB');
    return defaultCols;
  }

  columnWidthForName(name: string) {
    switch (name) {
      case 'SHOW ON TRACKING':
      case 'ARCHIVED':
      case 'TRACK JOB':
      case 'STATE':
      case 'EMAILED USERS':
      case 'WEEKLY CHECKIN':
      case 'ARCHIVE JOB':
        return 'small-jobs-width';
      case 'RECEIVER':
      case 'RECEIVER CONTACTS':
      case 'ADDRESS':
        return 'long-col-width';
      default:
        return 'default-col-width';
    }
  }

  inputEnter(event: any) {
    event.target.blur();
  }

  generateRandomString() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  getDynamicStyles() {
    if (this.notificationsView && this.panelOpen) {
      const width = `calc(100vw - 550px)`;
      return { 'max-width': width };
    } else {
      return { 'max-width': '' };
    }
  }
}
