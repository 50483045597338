import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import { IUserManagement, IUserPassword } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl: string = environment.ENV.BASE_URL;

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getUserProfile(): Observable<IUserManagement | HttpErrorResponse> {
    return this.httpClient.get<IUserManagement>(`${this.baseUrl}users/profile/`, this.httpHelper.options);
  }

  public getOrgUsers(): Observable<IUserManagement | HttpErrorResponse> {
    return this.httpClient.get<IUserManagement>(`${this.baseUrl}users/users/`, this.httpHelper.options);
  }

  public updateUserProfile(updatedProfile: any): Observable<IUserManagement | HttpErrorResponse> {
    return this.httpClient.put<IUserManagement>(`${this.baseUrl}users/profile/`, updatedProfile.payload, this.httpHelper.options);
  }

  public updateUserProfilePicture(updatedProfile: any): Observable<IUserManagement | HttpErrorResponse> {
    return this.httpClient.post<any>(`${this.baseUrl}users/picture/`, updatedProfile);
  }

  public updatePassword(updatedPassword: any): Observable<IUserPassword | HttpErrorResponse> {
    return this.httpClient.post<IUserPassword>(`${this.baseUrl}users/change-password/`, updatedPassword.payload, this.httpHelper.options);
  }

  public loadNotificationMetaData(): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}users/meta/`, this.httpHelper.options);
  }

}
