<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
<div class="auth-container sm:mx-auto sm:w-full sm:max-w-sm">
  <div class="logo">
    <img src="/assets/img/materlog_logo.svg" alt="materlog-logo" />
  </div>

  <div class="form m-4 sm:m-0 max-w-md sm:max-w-xl">
    <div class="title">Sign in</div>
    <form [formGroup]="signInForm" class="form-group">
      <div class="textfield">
        <label class="textfield__label" for="email">Email</label>
        <input type="text" id="email" formControlName="email" class="form-control" placeholder="name@company.com" />
        <div
          class="textfield__error-message"
          *ngIf="signInForm.controls.email.invalid && signInForm.controls.email.touched && isSubmitted"
        >
          Enter a valid email address
        </div>
      </div>

      <div class="textfield">
        <div class="textfield__inline">
          <label class="textfield__label" for="password">Password</label>
          <div class="link link--grey" (click)="onNavigateToForgotPassword()">Forgot password?</div>
        </div>
        <input type="password" id="password" formControlName="password" class="form-control" placeholder="********" />
        <div
          class="textfield__error-message"
          *ngIf="signInForm.controls.password.invalid && signInForm.controls.password.touched && isSubmitted"
        >
          Enter password
        </div>
        <div class="textfield__error-message" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
      </div>

      <button
        pButton
        type="submit"
        label="Continue"
        class="button-primary p-button-raised"
        (click)="onSubmit()"
      ></button>
    </form>
    <div class="disclaimer">
      or
      <div class="link" (click)="onNavigateToSignUp()">create an account</div>
    </div>
  </div>
</div>
  </div>

