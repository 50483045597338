<div class="user-settings-container" #userSettingsContainer>
  <ng-container *ngIf="isLoading">
    <div class="flex flex-col">
      <div style="margin-left: 20px"></div>
      <p-skeleton height="20px" width="200px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
      <div style="margin-bottom: 10px"></div>
      <div class="flex flex-row" style="margin-top: 10px">
        <p-skeleton height="80px" width="80px" styleClass="mb-2" borderRadius="50%"></p-skeleton>
        <div style="margin-right: 20px;"></div>
        <div class="flex flex-col">
          <p-skeleton height="20px" width="200px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
          <div style="margin-bottom: 4px"></div>
          <p-skeleton height="20px" width="200px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
          <div style="margin-bottom: 4px"></div>
          <p-skeleton height="20px" width="200px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
          <div style="margin-bottom: 4px"></div>
        </div>
      </div>
      <div class="flex flex-col">
        <div style="margin-top: 40px"></div>
        <div *ngFor="let item of skeletonItems">
          <p-skeleton height="20px" [width]="item.width" styleClass="mb-4" borderRadius="16px"></p-skeleton>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!isLoading" class="user-settings-data-container">
    <div class="user-settings-user-profile">
      <div class="user-settings-account-overview">
        Account Overview
      </div>
      <div class="user-settings-profile-container">
        <div class="user-settings-profile-picture">
          <img *ngIf="userData.image_profile" [src]="userData.image_profile">
          <i *ngIf="!userData.image_profile" class="bi bi-person no-image-profile"></i>
        </div>
        <div class="user-settings-edit-picture" >
          <label (click)="uploadFile($event)">
            <i class="bi bi-pencil user-settings-pencil"></i>
            <input type="file" #fileInput (change)="onFileSelected($event)">
          </label>
        </div>
        <div class="user-settings-profile-text-container">
          <div *ngIf="!editingName" class="user-settings-profile-name"
            (click)="userInputClicked(true)"
            [ngStyle]="{'color': !hasName ? 'rgba(150, 150, 150, 1)': ''}">
            {{ getName }}
          </div>
          <input *ngIf="editingName"
            class="user-settings-input-name user-settings-input-name-username"
            type="text"
            id="user-settings-username"
            placeholder="Your name"
            [(ngModel)] = "userName"
            (keydown.enter)="inputEnter($event)"
            (blur)="updateUserName($event)"
          />
          <div class="user-settings-profile-email">
            {{userData.user_email}}
          </div>
          <div class="user-settings-profile-password" *ngIf="!editingPassword">
            <i class="bi bi-pencil user-settings-password-pencil"
              (click)="editPasswordClicked()"
            ></i>
            Update password
          </div>
          <div *ngIf="editingPassword" class="user-settings-input-name-password">
            <p-password [(ngModel)]="oldPassword" [toggleMask]="true" class="user-settings-password-field"
                          placeholder="Old password" [feedback]="false" 
            />
            <p-password [(ngModel)]="newPassword1" [toggleMask]="true" class="user-settings-password-field"
                        placeholder="New password" [disabled]="isPasswordDisabled(1)" [feedback]="false"
            />
            <p-password [(ngModel)]="newPassword2" [toggleMask]="true" class="user-settings-password-field"
                          placeholder="Retype new password" [disabled]="isPasswordDisabled(2)" [feedback]="false"
            />
            <div class="user-settings-password-box">
              <div class="user-settings-cancel-password" (click)="cancelPassword()"> 
                Cancel
              </div>
              <div class="user-settings-confirm-password" (click)="resetPassword()"
                    [ngStyle]="{
                      'cursor': canSubmitPassword ? 'pointer': 'default',
                      'background-color': canSubmitPassword ? '#17CAF1': 'rgba(40, 188, 220, .3)',
                      'color': canSubmitPassword ? 'white': 'rgba(130, 130, 130, .6)'
                    }">
                <div *ngIf="isPasswordLoading && !passwordSuccess"
                      class="user-settings-password-loading-container">
                  <div class="user-settings-password-loading"></div>
                </div>
                <div *ngIf="!isPasswordLoading && !passwordSuccess">
                  Change password
                </div>
                <div *ngIf="passwordSuccess">
                  Success!
                </div>
              </div>
            </div>
            <div class="user-settings-password-error" *ngIf="getPasswordError">
              {{getPasswordError}}
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="user-settings-options">
      <div class="user-settings-notifications-header">
        <div class="user-settings-notifications-header-main">
          Notifications
        </div>
        <div class="user-settings-notifications-header-sub">
          <div class="user-settings-customize-text">
            Customize the notifications you’d like to receive from Meti  (these are personal to you)
          </div>
          <div class="restore-defaults" (click)="restoreDefaults()">
            Restore defaults
          </div>
        </div>
      </div>
      <div class="user-settings-all-options" *ngFor="let opt of settings; let isFirst = first">
        <div class="user-settings-high">
          <div class="user-settings-high-text">
            {{opt.name}}
          </div>
          <div class="user-settings-toggles">
            <div class="user-settings-app-email user-settings-app-email-text" *ngIf="isFirst">
              In app
            </div>
            <div class="user-settings-app-email user-settings-app-email-text" *ngIf="isFirst">
              Email
            </div>
          </div>
        </div>
        <div *ngFor="let subOpt of opt.options; let isLast=last"
              class="user-settings-all-options"
              [ngClass]="isLast ? 'user-settings-final' : 'user-settings-low'"
              >
          <div class="user-settings-suboptions">
            <div class="user-settings-low-text">
              {{subOpt.name}}
            </div>
            <div class="user-settings-toggles">
              <div class="user-settings-app-email user-settings-app-email-text">
                <p-checkbox  *ngIf="subOpt.app"
                  [(ngModel)]="subOpt.app_data"
                  name="jobs"
                  [binary]="true"
                  (onChange)="updateUserOptions($event)">
                </p-checkbox>
              </div>
              <div class="user-settings-app-email user-settings-app-email-text" >
                <p-checkbox *ngIf="subOpt.email"
                  [(ngModel)]="subOpt.email_data"
                  name="jobs"
                  [binary]="true"
                  (onChange)="updateUserOptions($event)">
                </p-checkbox>
              </div>
            </div>
          </div>
          <div *ngIf="subOpt.subtext" class="user-settings-subtext">
            {{subOpt.subtext}}
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
