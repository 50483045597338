<div class="card-container custom-scrollbar-close" #cardContainer
      [ngStyle]="{'max-width': getDynamicStyles()}">
  <div *ngIf="isLoading || isLoadingOverlay || isLoadingOrder">
    <div *ngFor="let item of skeletonItems" style="padding: 4px 8px 4px 12px;">
      <p-skeleton [width]="item.width" [height]="'50px'" styleClass="mb-2" borderRadius="16px"></p-skeleton>
    </div>
  </div>
  <div *ngIf="!(isLoading || isLoadingOverlay || isLoadingOrder)">
    <div class="single-card custom-scrollbar-close" *ngFor="let card of curCards; let card_idx = index"
        [ngClass]="notificationsView ? 'single-card-notif': 'single-card-default'"
        [ngStyle]="{
          'max-width': !notificationsView ? getCardWidth(card): '',
          'min-width': !notificationsView ? getCardWidth(card): '',
          'margin': notificationsView ? '1px': '14px',
          }"
        (mouseenter)="hoverOverCard(card)">
      <div class="card-order-item">
        <div class="card-order" (click)="setCardOpen($event, card)"
            [ngClass]="{'card-open': card.open && !card.is_order}">
          <div class="card-column card-column-primary">
            <div class="single-col-row">
              <div class="card-supplier">
                <dropdown-cell-renderer
                  [options]="dropdownOptions.supplier_id"
                  [isAgGrid]="false"
                  [data]="card.supplier"
                  [cardView]="true"
                  [colField]="'supplier_id'"
                  (statusChanged)="onUpdateDropdown($event, card, null)"
                ></dropdown-cell-renderer>
              </div>
              <div class="card-job">
                <dropdown-cell-renderer
                  [options]="dropdownOptions.job_id"
                  [isAgGrid]="false"
                  [data]="card.job"
                  [cardView]="true"
                  [colField]="'job_id'"
                  (statusChanged)="onUpdateDropdown($event, card, null)"
                ></dropdown-cell-renderer>
              </div>
            </div>
            <div class="single-col-row single-col-row-docs">
              <div class="card-docs custom-scrollbar-close">
                <div *ngFor="let doc of card.odocs; let isLast = last">
                  <div class="single-card-doc"
                      [ngClass]="isLast? 'new-order-doc': ''">
                    <orders-cell-renderer
                    [isApprovals]="true"
                    [cardView]="true"
                    [docData]="doc"
                    (orderUpdated)="onOrderDocChange($event, card)"
                    >
                    </orders-cell-renderer>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-column card-column-secondary">
            <div class="single-col-row">
              <div class="card-delivery">
                <div class="card-note">
                    {{actEstDeliveryDate(card.items)}}
                </div>
                <div class="card-delivery-inner">
                  <div class="card-delivery-when" (click)="showCardCalendar($event, card)"
                      [ngClass]="isValidDate(card.delivery) || !card.delivery ? 'card-delivery-editable' : ''">
                      {{card.delivery ? card.delivery : '--'}}
                  </div>
                  <div *ngIf="isValidDate(card.delivery)" class="card-deliver-delete">
                    <i class="bi bi-x" (click)="deleteAllDates($event, card)"></i>
                  </div>
                </div>

              </div>
            </div>
            <div class="single-col-row">
              <div class="card-delivery ">
                <div class="card-note">
                  ITEM STATUSES ({{card.is_order ? 0 : card.items.length}})
                </div>
                <div class="item-wrap custom-scrollbar-close" >
                  <div class="item-status" *ngFor="let status of card.status"
                        [ngStyle]="{
                          'background-color': status.color,
                        }">
                    <dropdown-cell-renderer
                        [options]="dropdownOptions.status"
                        [isAgGrid]="false"
                        [data]="status"
                        [cardViewWidth]="status.name.length * 8 + 20"
                        [cardView]="true"
                        [colField]="'status'"
                        (statusChanged)="orderStatusChange($event, card)"
                    ></dropdown-cell-renderer>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-column card-column-secondary">
            <div class="card-tracking">
              <div class="card-note">
                TRACKING
              </div>
              <div class="single-col-row card-tracking-links card-tracking-order custom-scrollbar-close" *ngIf="!card.is_order">
                <shiplink-cell-renderer
                [cardView]="true"
                [linkData]="card.tracking"
                [maxWidth]="140"
                (onLinkChange)="onLinkChange($event, card)"
                >
                </shiplink-cell-renderer>
              </div>
            </div>
          </div>
          <div class="card-column card-column-notes">

            <div class="single-col-row">
              <div class="card-delivery notes-textarea-container">
                <div class="last-order-col">
                  <div class="card-note">
                    NOTES
                  </div>
                  <div class="delete-order">
                    <i class="bi bi-trash3-fill" (click)="deleteOrder($event, card)"
                        style="font-size: 12px; color: #ddd"></i>
                    <div class="delete-order-tooltip delete-tooltip">
                      Delete order?
                    </div>
                  </div>
                </div>

                <div class="card-delivery-when notes-textarea-pad" *ngIf="!card.is_order">
                  <textarea class="notes-input custom-scrollbar" 
                    [(ngModel)]="card.notes" 
                    [placeholder]="card.notes"
                    (focus)="onNotesFocus($event, card)"
                    (click)="onNotesClick($event, card)"
                    (blur)="onNotesBlur($event, card, 'order_notes')"
                    (keydown)="onNotesKeydown($event, card)"
                    rows="3">
                  </textarea>
                </div>
              </div>
            </div>
            <div class="single-col-row">
              <div class="card-delivery">
                <div class="card-note">
                    DOCS
                </div>
                <div class="card-all-order-docs custom-scrollbar-close">
                  <div class="card-order-doc" *ngFor="let doc of card.docs">
                    <div class="order-doc-type"
                        [ngStyle]="{'max-width': getStatusWidth(doc.document_type),
                                    'min-width': getStatusWidth(doc.document_type)}">
                      {{doc.document_type}}
                    </div>
                    <orders-doc-cell-renderer *ngIf="card.showDocs || (card_idx < 8)"
                    [isApprovals]="true"
                    [cardView]="true"
                    [docData]="doc"
                    (onOrderAttChange)="onOrderAttChange($event, card)"
                    >
                    </orders-doc-cell-renderer>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!card.sidebar" class="card-column card-column-secondary checkin-column">
            <div class="card-delivery">
              <div class="card-note activity-history" (click)="showActivityHistoryTooltip($event, card)">
                  ACTIVITY HISTORY
                  <i class="bi bi-chevron-right" style="padding-left: 2px"></i>
                  <div class="activity-tooltip" *ngIf="card.activity_tooltip">
                    <div class="single-activity"
                          [ngStyle]="{'background-color': act == card.cur_activity ? '#eee': ''}"
                          *ngFor="let act of activityList"
                          (click)="onChangeActivity($event, card, act)">
                      <div class="single-activity-text">
                        {{act}}
                      </div>
                      <i *ngIf="act == card.cur_activity" class="bi bi-check2"
                            style="padding-right: 6px; max-height: 8px; font-size: 12px; color: #838383"></i>
                    </div>
                  </div>
              </div>
              <div class="card-checkin">
                <div class="card-note checkin-note">
                  NEXT SUPPLIER CHECK IN
                </div>
                <div class="next-checkin-globe">
                  <div class="card-checkin-text">
                    {{card.next_checkin}}
                  </div>
                  <div class="tracking-globe-container" *ngIf="card.next_checkin == 'Regularly'">
                    <a  *ngIf="card.supplier_tracking_link"
                        [href]="card.supplier_tracking_link" class="supplier-tracking-link"
                      id="placeholderLink"  target="_blank" rel="noopener noreferrer">
                      <i class="bi bi-globe tracking-globe"></i>
                    </a>
                    <i *ngIf="!card.supplier_tracking_link" class="bi bi-globe tracking-globe"></i>
                  </div>
                  <div class="tracking-globe-container" *ngIf="card.over_email">
                    <i class="bi bi-envelope tracking-globe"></i>
                  </div>
                </div>

              </div>
              <div class="card-checkin">
                <div class="card-note checkin-note">
                  LAST ACTIVITY
                </div>
                <div class="card-checkin-text"
                [ngStyle]="{'max-width': getStatusWidth(card.last_activity_date),
                'min-width': getStatusWidth(card.last_activity_date)}">
                  {{card.last_activity_date}}
                </div>
              </div>
              <div class="card-checkin">
                <div class="card-note checkin-note">
                  LAST UPDATED
                </div>
                <div class="card-checkin-text"
                [ngStyle]="{'max-width': getStatusWidth(card.last_updated_date),
                'min-width': getStatusWidth(card.last_updated_date)}">
                  {{card.last_updated_date}}
                </div>
              </div>
              <div class="card-meta-counts">
                <div class="card-meta-single-count" (click)="showCardEmails($event, card)"
                    [ngClass]="card.cur_activity == actEmail ? 'active-activity-type' : '' ">
                  <i class="bi bi-envelope card-meta-icon" ></i>
                </div>
                <div class="card-meta-single-count" (click)="showCardDrafts($event, card)"
                    [ngClass]="card.cur_activity == actEmail ? 'active-activity-type' : '' ">
                  <i *ngIf="card.draft_count" class="bi bi-dot card-meta-icon-dot"></i>
                  <i class="bi bi-journal card-meta-icon" ></i>
                </div>
                <div class="card-meta-single-count" (click)="showCardComments($event, card)"
                    [ngClass]="card.cur_activity == actComm ? 'active-activity-type' : '' ">
                  <i *ngIf="card.comment_count" class="bi bi-dot card-meta-icon-dot"></i>
                  <i class="bi bi-chat card-meta-icon" ></i>
                </div>
                <div class="card-meta-single-count" (click)="showSubmittals($event, card)"
                    [ngClass]="card.cur_activity == subHistory ? 'active-activity-type' : '' ">
                  <i class="bi bi-list-check card-meta-icon" ></i>
                </div>
                <div class="card-meta-single-count" (click)="showCardNotifications($event, card)"
                    [ngClass]="card.cur_activity == actNotif ? 'active-activity-type' : '' ">
                  <i *ngIf="card.notification_count" class="bi bi-dot card-meta-icon-dot"></i>
                  <i class="bi bi-activity card-meta-icon" ></i>
                </div>
                <div *ngIf="card.cur_activity == subHistory" 
                      class="card-meta-single-count quick-add-submittals" >
                  <div class="global-submittal-button" (click)="showGlobalSubmittals($event, card)">
                    Submittals quick view
                  </div>
                  <div *ngIf="activeJob?.name != 'All jobs'"
                      class="global-submittal-button quick-add-submittals" (click)="exportSubmittalsToPDF($event)">
                    All orders view
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-item custom-scrollbar-close" *ngIf="card.open && !card.is_order"
            [ngClass]="{'item-width-small': card.sidebar, 'item-width-large': !card.sidebar}">
          <div class="single-item-complete" *ngFor="let item of card.items; let idx = index; let isLast = last"
              [ngClass]="!isLast ? 'card-bottom': ''">
            <div class="single-item">
              <div class="card-column card-column-primary">
                <div class="single-item-row-full item-images custom-scrollbar-close">
                    <images-cell-renderer
                      [cardView]="true"
                      (onImagesUpdated)="onImagesUpdated($event, card, item)"
                      [inputData]="getImageData(card, item)">
                    </images-cell-renderer>
                </div>
              </div>
              <div class="card-column card-column-secondary">
                <div class="single-item-row">
                  <div class="card-delivery item-input-container">
                    <div class="card-note">
                        ITEM {{idx + 1}}
                    </div>
                    <div class="card-delivery-when name-overflow">
                      <input class="card-column-secondary-input item-text-input"
                      type="text"
                      (focus)="startEditingItem($event, card, item)"
                      (keydown.enter)="blurEditingValue($event)"
                      (blur)="stopEditingTracking(item, item.name, 'name')"
                      [(ngModel)]="item.name"/>
                    </div>
                  </div>
                </div>
                <div class="single-item-row">
                  <div class="card-delivery item-input-container">
                    <div class="card-note">
                      SPEC
                    </div>
                    <div class="card-delivery-when name-overflow">
                      <input class="card-column-secondary-input item-text-input"
                      type="text"
                      (focus)="startEditingItem($event, card, item)"
                      (keydown.enter)="blurEditingValue($event)"
                      (blur)="stopEditingTracking(item, item.spec, 'spec')"
                      [(ngModel)]="item.spec"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-column card-column-secondary">
                <div class="single-item-row">
                  <div class="card-delivery item-input-container">
                    <div class="card-note">
                        QUANTITY
                    </div>
                    <div class="card-delivery-when card-checkin">
                      <div class="card-delivery-when">
                        <div class="card-delivery-when name-overflow">
                          <input class="small-item-input item-text-input"
                          type="text"
                          (focus)="startEditingItem($event, card, item)"
                          (keydown.enter)="blurEditingValue($event)"
                          (blur)="stopEditingTracking(item, item.quantity, 'quantity')"
                          [(ngModel)]="item.quantity"/>
                        </div>
                      </div>
                      <div class="card-delivery-when item-quantity-measure">
                        <dropdown-cell-renderer
                        [options]="card.uoms"
                        [isAgGrid]="false"
                        [data]="item.uom"
                        [cardView]="true"
                        [colField]="'unit_of_measure'"
                        (statusChanged)="onUpdateDropdown($event, card, item, true)"
                      ></dropdown-cell-renderer>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="single-item-row">
                  <div class="card-delivery item-input-container">
                    <div class="card-note">
                      SKU
                    </div>
                    <div class="card-delivery-when name-overflow">
                      <input class="card-column-secondary-input item-text-input"
                      type="text"
                      (focus)="startEditingItem($event, card, item)"
                      (keydown.enter)="blurEditingValue($event)"
                      (blur)="stopEditingTracking(item, item.sku, 'sku')"
                      [(ngModel)]="item.sku"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-column item-column-small">
                <div class="single-item-row">
                  <div class="card-delivery">
                    <div class="card-note">
                        STATUS
                    </div>
                    <div class="item-status"
                          [ngStyle]="{
                            'background-color': item.status_color.color, 
                            'max-width': getStatusWidth(item.status_color.name.value, true, 25),
                            'min-width': getStatusWidth(item.status_color.name.value, true, 25)
                          }">
                        <dropdown-cell-renderer
                          [options]="dropdownOptions.status"
                          [isAgGrid]="false"
                          [data]="item.status_color.name"
                          [cardViewWidth]="120"
                          [cardView]="true"
                          [colField]="'status'"
                          (statusChanged)="onUpdateDropdown($event, card, item, true)"
                        ></dropdown-cell-renderer>
                    </div>
                  </div>
                </div>
                <div class="single-item-row">
                  <div class="card-delivery">
                    <div class="card-note">
                        {{actEstInStockDate(item)}}
                    </div>
                    <div class="card-delivery-inner">
                      <div class="card-delivery-when name-overflow calendar-wrapper"
                            (click)="openCalendar($event, card, item, 'in_stock_date')">
                        {{createDate(item.in_stock_date)}}
                      </div>
                      <div *ngIf="item.in_stock_date" class="card-deliver-delete">
                        <i class="bi bi-x" (click)="deleteDate($event, card, item, 'in_stock_date')"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-column item-column-small">
                <div class="single-item-row">
                  <div class="card-delivery">
                    <div class="card-note">
                        {{actEstShipDate(item)}}
                    </div>
                    <div class="card-delivery-inner">
                      <div class="card-delivery-when name-overflow calendar-wrapper"
                            (click)="openCalendar($event, card, item, 'ship_date')">
                        {{createDate(item.ship_date)}}
                      </div>
                      <div *ngIf="item.ship_date" class="card-deliver-delete">
                        <i class="bi bi-x" (click)="deleteDate($event, card, item, 'ship_date')"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="single-item-row">
                  <div class="card-delivery">
                    <div class="card-note">
                        {{actEstItemDeliveryDate(item)}}
                    </div>
                    <div class="card-delivery-inner">
                      <div class="card-delivery-when name-overflow calendar-wrapper"
                            (click)="openCalendar($event, card, item, 'delivery_date')">
                        {{createDate(item.delivery_date)}}
                      </div>
                      <div *ngIf="item.delivery_date" class="card-deliver-delete">
                        <i class="bi bi-x" (click)="deleteDate($event, card, item, 'delivery_date')"></i>
                      </div>
                    </div>
  
                  </div>
                </div>
              </div>
              <div class="card-column item-column-small">
                <div class="single-item-row">
                  <div class="card-delivery">
                    <div class="card-note">
                        TRACKING
                    </div>
                    <div class="card-tracking-links card-tracking-links-item custom-scrollbar-close">
                      <shiplink-cell-renderer
                        [cardView]="true"
                        [linkData]="item.tracking_links"
                        [maxWidth]="130"
                        (onLinkChange)="onLinkChange($event, item, true)"
                      >
                      </shiplink-cell-renderer>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!card.sidebar" class="card-column card-column-notes">
                <div class="single-item-row-full">
                  <div class="card-delivery notes-textarea-container">
                    <div class="delete-item">
                      <i class="bi bi-trash3-fill" (click)="deleteItem($event, card, item)"
                          style="font-size: 12px; color: #ddd"></i>
                      <div class="delete-item-tooltip delete-tooltip">
                        Delete item?
                      </div>
                    </div>
                    <div class="card-note">
                        NOTES
                    </div>
                    <div class="card-delivery-when notes-textarea-pad">
                      <textarea class="notes-input custom-scrollbar" 
                        [(ngModel)]="item.notes" 
                        [placeholder]="item.notes"
                        (click)="onNotesClick($event, card)"
                        (focus)="onNotesFocus($event, item)" 
                        (blur)="onNotesBlur($event, item, 'notes', true)"
                        (keydown)="onNotesKeydown($event, item)"
                        rows="3">
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="card.show_submittals" class="submittals-outer">
              <submittals
              [itemID]="item.id"
              [submittalData]="card.submittals_data"
              [submittalHistory]="card.submittals_history"
              [options]="submittalOptions"
              (newSubmittalOptions)="createSubmittalOptions()"
              ></submittals>
            </div>
          </div>
        </div>
      </div>
      <div class="activity-container" *ngIf="card.sidebar"
          [ngStyle]="{
            'max-width': getCardWidth(card, true),
            'min-width': getCardWidth(card, true),
            }">
        <div class="activity-header">
          <div class="activity-header-type">
            <div class="activity-header-type" (click)="showActivityHistoryTooltip($event, card)"
              [ngStyle]="{
                'max-width': getStatusWidth(card.cur_activity, true),
                'min-width': getStatusWidth(card.cur_activity, true)
              }">
              {{card.cur_activity}}
              <i class="bi bi-caret-down-fill" style="padding-left: 8px"></i>
              <div class="activity-tooltip" style="margin-top: 16px" *ngIf="card.activity_tooltip">
                <div class="single-activity"
                      [ngStyle]="{'background-color': act == card.cur_activity ? '#eee': ''}"
                      *ngFor="let act of activityList"
                      (click)="onChangeActivity($event, card, act)">
                  <div class="single-activity-text">
                    {{act}}
                  </div>
                  <i *ngIf="act == card.cur_activity"
                      class="bi bi-check2"
                      style="padding-right: 6px; max-height: 12px; font-size: 12px; color: #838383">
                  </i>
                </div>
              </div>
            </div>
            <div class="activity-header-type" *ngIf="card.cur_activity == actComm">
              <i class="bi bi-plus" (click)="commentClick($event, card)"
                  style="margin-left: 2px; max-height: 16px; font-size: 16px; position: relative; top: -4px;"></i>
            </div>
          </div>

          <div class="flex-spacer">
          </div>
          <div class="close-activity">
            <i class="bi bi-x close-activity" (click)="closeActivity($event, card)"></i>
          </div>
        </div>
        <div class="order-comments" *ngIf="card.cur_activity == actComm">
          <comment-pane
            [selectedView]="selectedView"
            [orgUserEmails]="orgUserEmails"
            [cardView]="true"
            [card]="card"
            [cardHeight]="card.open ? 600: 160"
          >
          </comment-pane>
        </div>
        <div class="order-comments" *ngIf="card.cur_activity == actEmail">
          <app-mail-list
            [selectedView]="selectedView"
            [cardView]="true"
            [card]="card"
            [cardHeight]="card.open ? 600: 160"
            [cardViewShowDrafts]="showDrafts"
            [allMailData]="allMailData"
          >
          </app-mail-list>
        </div>
        <div class="order-comments activity-history-outer" *ngIf="card.cur_activity == actNotif">
          <activity-history
          [dropdownOptions]="dropdownOptions"
          [orderId]="card.items[0].order_id"
          >
          </activity-history>
        </div>
      </div>
    </div>
  </div>
</div>


<popup-comment
  *ngIf="showPopupComment"
  [rowNode]="curCard"
  [mousePosition]="mousePosition"
  [orgUserEmails]="orgUserEmails"
  [cardView]="true"
  (closePopup)="delayClose()"
></popup-comment>

<div #calendar class="calendar-container" *ngIf="calendarVisible">
  <p-calendar class="max-w-full"
  [style]="{
    'width':'100%', 'height': '100%', 'z-index': '5',
    'position': 'relative', 'bottom': '-72px',
    'right': '70px',
  }"
  placeholder="YYYY-MM-DD"
  dateFormat="yy/mm/dd"
  dataType="date"
  [inline]="true"
  appendTo="body"
  (onSelect)="onChangeDate($event)"
  (onClear)="onChangeDate($event)">
</p-calendar>
</div>


<div class="global-submittals-dialog" #submittalsDialog>
  <p-dialog
  [(visible)]="showGlobalSubmittalsModal"
  [style]="{ marginTop: '50px', width: '90vw', height: '90vh', padding: '0px'}"
  [closeOnEscape]="true"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="content">
    <global-submittals
      (close)="showGlobalSubmittalsModal = false"
      (newSubmittalType)="addNewSubmittalType($event)"
      (deleteSubmittalType)="deleteSubmittalType($event)"
      (changeSubmittalTypeName)="changeSubmittalTypeName($event)"
      (applySubmittals)="onApplySubmittals($event)"
      [card]="currentGlobalCard"
      [submittalData]="currentGlobalCard.submittals_data"
      [options]="submittalOptions"
    >
    </global-submittals>
  </ng-template>
</p-dialog>
</div>


<div class="global-submittals-dialog" #jobSubmittalsDialog>
  <p-dialog
  [(visible)]="showJobSubmittalsModal"
  [style]="{ width: '90vw', height: '100vh', padding: '0px'}"
  [closeOnEscape]="true"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="content">
    <submittals-export
      [job]="activeJob"
      (close)="showJobSubmittalsModal = false"
      ></submittals-export>
  </ng-template>
</p-dialog>
</div>

