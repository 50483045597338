import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { SelectedViewModel } from '../../models/selected-view.model';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { DashboardRoutes } from '../../enums/dashboard-routes.enum';
import { ElementRef, HostListener } from '@angular/core';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { selectComments, selectViews} from '../../state/selectors/dashboard.selector';
import { selectActiveJobs } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IUserManagement } from 'src/app/user-settings/models/user.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CommentsService } from '../../services/comment.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { MentionModule } from 'angular-mentions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { deepCopy } from 'src/app/common/utils/general';
import { ActiveJobModel } from '../../models/active-job.model';
import { selectUserProfile } from 'src/app/user-settings/state/selectors/user.selector';
import { UserActions } from 'src/app/user-settings/state/actions/user.actions';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { PermissionsService } from 'src/app/root/services/permissions.service';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UserService } from 'src/app/user-settings/services/user.service';
// import { OrderUploadService } from '../../services/order-upload.service';



@Component({
  selector: 'side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.css'],
})
export class SidePanelComponent implements OnInit {

  private baseUrl: string = environment.ENV.BASE_URL;
  tawk_id: string = environment.ENV.TAWK_ID;

  initials: string = '';
  newOptionsVisible: boolean = false;
  newOptionTypes = ['Item', 'Order', 'Job', 'View'];
  notificationTypes = ['Comments', 'Notifications', 'Pulls'];
  activenotificationType: any = undefined;
  activePageType: any = 'Items';
  profile: any = {};

  mailBoxVisible: boolean = false;

  showTemplates: boolean = false;
  showNewJob: boolean = false;
  showNewOrder: boolean = false;
  showNewItem: boolean = false;
  showNewReceiver: boolean = false;
  addSupplierModal: boolean = false;

  @ViewChild('newOptionsButton') newOptionsButton!: ElementRef;
  @Input() selectedViewStyle: number = -1;
  @Input() selectedView?: SelectedViewModel;
  @Input() isLoading: any;
  @Input() isLoadingViews: any;
  @Input() activeJobs: ActiveJobModel[] = [];
  @Input() panelHeight!: 750;
  @Input() allMailData: any;
  @Output() selectedJob: EventEmitter<any> = new EventEmitter();

  commentCnt: number = 0;
  confirmationCnt: number = 0;
  draftCnt: number = 0;
  notificationCnt: number = 0;
  notifReceived: boolean = false;

  initDataCalled: boolean = false;

  ngOnInit() {
    this.onRequestUserProfile();
    // this.onRequestMetaData();
    // this.orderUploadService.getOrderStatusUpdates().subscribe((result: any) => {
    //   this.onRequestMetaData();
    // })
    this.djv.notificationCntObs$.subscribe((data: any) => {
      this.notificationCnt = data;
    });
  }


  onPageReload() {

    let reloadPage = localStorage.getItem('activePage');
    if (reloadPage) {
      switch (reloadPage) {
        case 'Company':
          this.companySettingsClicked();
          break;
        case 'Items':
          this.resetItems();
          break;
        case 'Jobs':
          this.jobsClicked();
          break;
        case 'Suppliers':
          this.suppliersClicked();
          break;
        case 'User':
          this.userSettingsClicked();
          break;
        case 'Pulls':
          this.pullsClicked();
          break;
        case 'Notifications':
          this.notificationsClicked();
          break;
        default:
          this.resetItems();
          break;
      }
    }
  }

  ngOnChanges(changes: any) {
  }

  ngAfterViewInit() {
    // this.onRequestMetaData();
    this.onPageReload();
  }

  constructor(
    public router: Router,
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private cdr: ChangeDetectorRef,
    private userActions: UserActions,
    private djv: DashboardJobView,
    private mailBoxComm: MailBoxCommunicationService,
    private permissionService: PermissionsService,
    private httpClient: HttpClient,
    private userService: UserService,
    // private orderUploadService: OrderUploadService,
  ) {
    (window as any)['sp'] = this;

    this.djv.notificationCount$.subscribe((data: any) => {
      this.confirmationCnt = data;
    });
    this.djv.viewClick$.subscribe(() => {
      this.setItemPageActive();
    });

    this.djv.closeComments$.subscribe(() => {
      this.activenotificationType = undefined;
    });
    this.djv.closeEmail$.subscribe(() => {
      this.activenotificationType = undefined;
    });

    this.djv.requestMetadata$.subscribe(() => {
      this.onRequestMetaData();
    });

    this.djv.pollMetaData$.subscribe(() => {
      this.doPollMetaData();
    });

    this.store.pipe(select(selectComments)).subscribe((comment: IStoreApiItem<any>) => {
      if (this.dataReceived) this.onRequestMetaData();
    });
  }

  get dataReceived() {
    return this.profile.user_id && this.notifReceived;
  }

  createNewThing(event: any) {
    if (event == 'Item') {
      this.showNewItem = true;
    } else if (event == 'Order') {
      this.showNewOrder = true;
    } else if (event == 'Job') {
      this.showNewJob = true;
    } else if (event == 'View') {
      this.djv.make_new_view();
    } else if (event == 'Receiver') {
      this.showNewReceiver = true;
    } else if (event == 'Supplier') {
      this.addSupplierModal = true;
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    this.newOptionsVisible = false;
  }

  setNewOptionsVisible() {
    if (this.newOptionsVisible) {
      this.newOptionsVisible = false;
    } else {
      setTimeout(() => {
        this.newOptionsVisible = true;
      }, 0);
    }
  }

  isButtonInactive() {
    return this.activePageType == 'Notifications' ||  this.activePageType == 'Pulls';
  }

  alwaysClickable(type: any) {
    return ['Notifications', 'Pulls'].includes(type);

  }

  unsetPulls() {
    if (this.activenotificationType == 'Pulls') this.activenotificationType = undefined;
  }

  unsetNotifications() {
    if (this.activenotificationType == 'Notifications') this.activenotificationType = undefined;
  }

  resetItems() {
    this.activePageType = 'Items';
    this.setActivePageType();
  }

  onNotificationClick(which: any) {
    if (this.isButtonInactive() && !this.alwaysClickable(which)) return;
    if (this.zeroCount(which)) return;
    this.activenotificationType = which == this.activenotificationType ? undefined : which;
    if (which == 'Comments') {
      this.djv.side_panel_click({'icon': 'comments'});
    } else if (which == 'Drafts') {
      this.onRequestMetaData();
      this.djv.side_panel_click({'icon': 'drafts'});
    } else if (which == 'Pulls') {
      this.pullsClicked();
    } else if (which == 'Emails') {
      this.djv.side_panel_click({'icon': 'emails'});
    } else if (which == 'Notifications') {
      this.notificationsClicked();
    }
  }

  notificationsClicked() {
    this.onRequestMetaData();
    this.activePageType = 'Notifications';
    this.setActivePageType();
    this.activenotificationType = 'Notifications';
    this.djv.side_panel_click({'page': 'notifications'});
  }

  pullsClicked() {
    this.onRequestMetaData();
    this.activePageType = 'Pulls';
    this.setActivePageType();
    this.activenotificationType = 'Pulls';
    this.djv.side_panel_click({'page': 'confirmations'});
  }

  setActivePageType() {
    localStorage.setItem('activePage', this.activePageType);
  }

  emailsClicked() {
    if (this.isButtonInactive()) return;
    this.unsetPulls();
    this.unsetNotifications();
    this.onNotificationClick('Emails');
  }

  suppliersClicked() {
    this.activePageType = 'Suppliers';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.djv.side_panel_click({'page': 'suppliers'});
  }
  
  jobsClicked() {
    this.activePageType = 'Jobs';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.djv.side_panel_click({'page': 'jobs'});
  }

  userSettingsClicked() {
    this.activePageType = 'User';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.djv.side_panel_click({'page': 'user-settings'});
  }
  
  companySettingsClicked() {
    this.activePageType = 'Company';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.djv.side_panel_click({'page': 'company-settings'});
  }

  setItemPageActive() {
    this.activePageType = 'Items';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.djv.side_panel_click({'page': 'items'});
  }

  logoutClicked() {
    this.permissionService.logout();
  }

  templatesClicked() {
    this.showTemplates = true;
  }

  get userInitials() {
    return this.initials;
  }

  zeroCount(which: any) {
    if (this.notifCountForType(which) == 0 && which == 'Pulls') {
        return true;
     }
    return false;
  }

  highlightSide(which: any) {
    if (this.zeroCount(which)) return false;
    if (this.activenotificationType == which && this.alwaysClickable(which)) {
      return true;
    }
    if (this.activePageType == 'Items' &&
        this.selectedViewStyle == 1 &&
        !this.alwaysClickable(which)) {
      return false;
    }
    return this.activenotificationType == which;
  }

  notifCountForType(which: any) {
    if (which == 'Pulls') {
      return this.confirmationCnt;
    } else if (which == 'Drafts') {
      return this.draftCnt;
    } else if (which == 'Comments') {
      return this.commentCnt;
    } else if (which == 'Notifications') {
      return this.notificationCnt;
    }
    return 0;
  }

  selectJob(id: string): void {
    this.djv.side_panel_click({'page': 'items'});
    this.activePageType = 'Items';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.selectedJob.emit(id);
  }

  supplierFormChangeEvent(event: any) {
    setTimeout(() => {
      this.dashboardActions.requestSuppliers();
    }, 100);
    this.addSupplierModal = false;
  }

  getUserInitials(): void {
    let fl = this.profile.name.split(' ').slice(0, 2);
    fl = fl.map((l: any) => l[0].toUpperCase());
    fl = fl.join('');
    this.initials = fl;
  }

  loadNotificationMetaData(): Observable<any> {
    return this.userService.loadNotificationMetaData();
  }

  gotoNotifications() {
    this.showNewOrder = false;
    this.onNotificationClick('Notifications');
  }

  justSentMeta: boolean = false;
  onRequestMetaData() {
    if (this.justSentMeta) return;
    this.justSentMeta = true;
    this.loadNotificationMetaData()
      .subscribe((result: any) => {
        this.notifReceived = true;
        this.commentCnt = result.comments;
        this.confirmationCnt = result.confirmations;
        this.draftCnt = result.drafts;
        this.notificationCnt = result.notifications;
        this.cdr.detectChanges();
        setTimeout(() => {
          this.justSentMeta = false;
        }, 1000 * 5);
      })
  }

  onRequestUserProfile() {
    this.userActions.requestUserProfile();
    this.store
      .pipe(
        select(selectUserProfile),
        filter((userProfile: IStoreApiItem<IUserManagement>) => !userProfile.isLoading),
        take(1)
      )
      .subscribe((userProfile: any) => {
        this.profile.name = userProfile?.data?.first_name + ' ' + userProfile?.data?.last_name;
        this.profile.email = userProfile?.data?.user_email;
        this.profile.user_id = userProfile?.data?.user;
        localStorage.setItem('profile', JSON.stringify(this.profile));
        this.getUserInitials();
      });
  }

  doPollMetaData() {
    let startTime = Date.now();
    let pollingDuration = 5 * 60 * 1000;
    let pollingInterval = 60 * 1000;
    const pollInterval = setInterval(() => {
      this.onRequestMetaData();
      if (Date.now() - startTime >= pollingDuration) {
        clearInterval(pollInterval);
      }
    }, pollingInterval);
  }
}