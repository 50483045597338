

export function deepCopy(value: any) {
  return JSON.parse(JSON.stringify(value));
}

export function deepArrayClone(value: any) {
  // @ts-ignore
  return  value.map(a => {return {...a}})
}

export function emptySort(){
  return [];
}

export function isEmptySort(x: any){
  if (!x || x.length > 1)  return false;
  return x[0].key == 'created_datetime'
         && x[0].direction == true
         && x[0].position == 0
}

export function generateRandomString() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 15; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}