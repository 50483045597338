<div class="lightning-tooltip">
  These are fields that MaterLog's<br>
  automations will update if data about<br>
  that field is in an order update
</div>

<div class="main-page-container">
  <div class="job-list-container">
    <side-panel
      [activeJobs]="activeJobs"
      [selectedViewStyle]="selectedViewStyle"
      [isLoading]="isLoading"
      [isLoadingViews]="isLoadingViews"
      (selectedJob)="onSelectedJob($event)"
      [selectedView]="selectedView"
      [panelHeight]="panelHeight"
      [allMailData]="allMailData"
      [ngStyle]="{'display': sidePanelOpen ? 'block' : 'none'}">
    </side-panel>
  </div>
  <ng-container *ngIf="itemPageActive">
    <div class="a-page col-stretch">
      <div class="dashboard-filter-container a-page row-stretch"
            [ngClass]="selectedViewStyle == 0 ? 'border-bottom': ''">
        <card-controller
        [selectedView]="selectedView"
        (toggleFilter)="toggleFilter($event)"
        (selectedViewStyle)="onSelectViewStyle($event)"
        >
        </card-controller>
        <dashboard-filters *ngIf="filterActive && selectedViewStyle == 0"
            [activeJob]="activeJob"
            [selectedView]="selectedView"
            [isLoading]="isLoading"
            [mailBoxVisible]="sideDisplayVisible"
            (updateLoadingOverlay)="onUpdateLoadingOverlay()"
            (searchFormsChange)="onUpdateSearchTerm($event)"
            (imageSizeChange)="onUpdateImageSize($event)"
        ></dashboard-filters>
        <cardview-filter *ngIf="filterActive && selectedViewStyle == 1"
            [activeJob]="activeJob"
            [selectedView]="selectedView"
            [isLoading]="isLoading"
            [mailBoxVisible]="sideDisplayVisible"
            (searchFormsChange)="onUpdateSearchTerm($event)"
        ></cardview-filter>
      </div>
      <div class="content-container"
            [ngStyle]="{'display': selectedViewStyle == 1 ? 'block': 'none'}">
        <card-view 
          [isLoading]="isLoading || isLoadingViews"
          [isLoadingOverlay]="isLoadingOverlay"
          [selectedView]="selectedView"
          [orgUserEmails]="orgUserEmails"
          [activeJobsList]="activeJobs"
          [dropdownOptions]="dropdownOptions"
          [activeJob]="activeJob"
          [allMailData]="allMailData"
        ></card-view>
      </div>
      <div class="content-container"
        [ngStyle]="{'display': selectedViewStyle == 0 ? 'block': 'none'}">
        <job-table-v2
          [isLoading]="isLoading || isLoadingViews"
          [isLoadingOverlay]="isLoadingOverlay"
          [selectedView]="selectedView"
          [orgUserEmails]="orgUserEmails"
          [activeJobsList]="activeJobs"
          [dropdownOptions]="dropdownOptions"
          [activeJob]="activeJob"
          [responseItems]="responseItems"
          [orgSlug]="orgSlug"
          [isPasting]="isPasting"
          [allMailData]="allMailData"
        ></job-table-v2>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="jobPageActive">
    <div class="page-height"
      [ngClass]="sideDisplayVisible ? (mailVisible ? 'mail-inner' : 'comment-inner'): 'default-width'">
      <materlog-view-jobs
        [dropdownOptions]="dropdownOptions"
        [allMailData]="allMailData"
      ></materlog-view-jobs>
    </div>
  </ng-container>
  <ng-container *ngIf="supplierPageActive">
    <div
    [ngClass]="sideDisplayVisible ? (mailVisible ? 'mail-inner' : 'comment-inner'): 'default-width'">
      <materlog-suppliers-credentials-page
        [allMailData]="allMailData"
        [dashView]="true"
      ></materlog-suppliers-credentials-page>
    </div>
  </ng-container>
  <ng-container *ngIf="userSettingsActive">
    <div
    [ngClass]="sideDisplayVisible ? (mailVisible ? 'mail-inner' : 'comment-inner'): 'default-width'">
      <materlog-user-settings
        [dropdownOptions]="dropdownOptions"
        [allMailData]="allMailData"
      ></materlog-user-settings>
    </div>
  </ng-container>
  <ng-container *ngIf="companySettingsActive">
    <div
    [ngClass]="sideDisplayVisible ? (mailVisible ? 'mail-inner' : 'comment-inner'): 'default-width'">
      <materlog-company-settings
        [dropdownOptions]="dropdownOptions"
        [allMailData]="allMailData"
      ></materlog-company-settings>
    </div>
  </ng-container>
  <ng-container *ngIf="notifPageActive">
    <div
    [ngClass]="sideDisplayVisible ? (mailVisible ? 'mail-inner' : 'comment-inner'): 'default-width'">
      <notifications
      [selectedView]="selectedView"
      [dropdownOptions]="dropdownOptions"
      [activeJobsList]="activeJobs"
      [allMailData]="allMailData"
      ></notifications>
    </div>
  </ng-container>
  <ng-container *ngIf="approvalPageActive">
    <approvals
      [selectedView]="selectedView"
      [allMailData]="allMailData"
      [dropdownOptions]="dropdownOptions">
    </approvals>
  </ng-container>
  <div 
    [ngStyle]="{'display': mailVisible ? 'block' : 'none'}"
    class="mail-container">
    <app-mail-list
      [selectedView]="selectedView"
      [makeVisible]="mailVisible"
      [isLoading]="isLoading"
      [allMailData]="allMailData">
    </app-mail-list>
  </div>
  <div 
    [ngStyle]="{'display': commentPaneVisible ? 'block' : 'none'}"
    class="comment-container">
    <comment-pane
      [selectedView]="selectedView"
      [orgUserEmails]="orgUserEmails">
    </comment-pane>
  </div>
</div>


<!-- modal for first login with no jobs and empty dashboard  -->
<p-dialog
  appendTo="body"
  [(visible)]="showOnboardingDialog"
  [style]="{ width: '50%', minWidth: '500px', maxWidth: '800px' }"
  [contentStyle]="{ borderRadius: '7px' }"
  [draggable]="false"
  [modal]="true"
  [closeOnEscape]="false"
  [showHeader]="false"
>
  <get-tracking [openModal]="showOnboardingDialog" (openModalChange)="showOnboardingDialog = false"></get-tracking>
</p-dialog>

<p-toast position="top-center" key="error"></p-toast>

<router-outlet></router-outlet>
