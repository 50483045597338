import { AfterViewInit,Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

import { ActiveJobModel } from '../../models/active-job.model';
import { SelectedViewModel } from '../../models/selected-view.model';
import { OptionsService } from '../../services/options.service';
import {Router} from '@angular/router';
import { JobTableService } from "../../services/job-table.service";
import { DashboardJobView } from "../../services/dashboard_job_view.service";

@Component({
  selector: 'dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.sass'],
})
export class DashboardFiltersComponent implements AfterViewInit{
  @Input() selectedView?: SelectedViewModel;
  @Input() isLoading!: boolean;
  @Input() activeJob!: ActiveJobModel;
  @Input() mailBoxVisible: boolean = false;
  @Output() searchFormsChange = new EventEmitter<string>();
  @Output() imageSizeChange = new EventEmitter<string>();
  @Output() updateLoadingOverlay: EventEmitter<any> = new EventEmitter();
  @ViewChild('dashboardSearchAndHistory', {static: false}) dashboardSearchAndHistory?: ElementRef;

  sidePanelOpen: boolean = true;

  search: string = '';

  constructor(
    readonly optionsService: OptionsService,
    private jobTableService: JobTableService,
    private djv: DashboardJobView,
    private router: Router) {
  }

  onUpdateLoadingOverlay() {
    this.updateLoadingOverlay.emit()
  }

  isV1Route():boolean{
    return this.router.url.endsWith("v1");
  }

  ngOnInit() {
    (window as any)['df'] = this;
    let searchTerm = localStorage.getItem('searchPhrase') as string;
    if (this.selectedView?.activeView?.id) {
      this.search = searchTerm;
    }

    this.djv.searchFilterObs$.subscribe((data: any) => {
      this.search = '';
    });
  }

  ngAfterViewInit(): void {
  }

  clearSearch(){
    this.search = '';
    this.searchFormsChange.emit( this.search );

  }

  onFilterTextBoxChanged():void {
    this.searchFormsChange.emit(this.search );
  }


}
