import { getDateWithAPIFormat } from 'src/app/common/utils/date-utils/date.utils';
import { Output, EventEmitter } from '@angular/core';

import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';

import { MessageService } from 'primeng/api';
import { faExclamationTriangle, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { IActiveJobs, INewOrder, IOrders } from './../../models/dashboard.model';
import { IOptions } from './../../../authentication/models/authentication.model';
import { IStoreApiItem } from './../../../common/models/store-api-item.model';
import { selectSuppliers, selectOptions, selectActiveJobs, selectNewOrders, selectAutocompleteSuppliers, selectOrders } from './../../state/selectors/dashboard.selector';
import { DashboardActions } from './../../state/actions/dashboard.actions';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { ISuppliers } from '../../models/dashboard.model';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { OrderService } from '../../services/order.service';
import { DashboardJobView } from '../../services/dashboard_job_view.service';

@Component({
  selector: 'materlog-add-edit-po',
  templateUrl: './add-edit-po.component.html',
  styleUrls: ['./add-edit-po.component.sass', './add-edit-po.component.css'],
  providers: [DatePipe]
})
export class AddEditPoComponent extends OnDestroyMixin() implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Output() gotoNotif = new EventEmitter<void>();

  manualUpload: boolean = true;
  finishedUpload: boolean = false;

  showDialog: boolean = false;
  additionalDetails: boolean = false;
  supplierNameRequired: boolean = false;
  isSubmitted: boolean = false;
  wrongJob: boolean = false;
  wrongSupplier: boolean = false;
  wrongDocs: boolean = false;
  wrongDigits: boolean = false;
  isLoading: boolean = false;
  addSupplierModal: boolean = false;
  addItemsModal: boolean = false;
  didAddItems: boolean = false;
  addSupplierContactModal: boolean = false;
  decimalRegex = CommonConstants.decimalRegex;

  faTriangle = faExclamationTriangle;
  faXMark = faXmark;
  faEdit = faPenToSquare;

  curOrderId: any;
  itemList: any;
  suppliers: any;
  filteredSuppliers: any;
  filteredSupplierContacts: any;
  shipTo: any;
  paymentMethod: any;
  documentTypes: any;
  activeJobs: any;
  contactRoles: any;
  shippingCountries: any;
  shippingStates: any;

  poDocForm: any;
  poDocArray: any[] = [];
  supplierDocForm: any;

  ordersForm: any = {
    job: '',
    supplier: {
      id: null,
      name: '',
      contacts: null,
    },
    supplier_contacts: {
      id: null,
      name: null,
    },
    shipping_to: 0,
    shipping_name: null,
    shipping_address: null,
    shipping_city: null,
    shipping_zip: null,
    shipping_country: 1,
    shipping_state: null,
    total_cost: null,
    payment_method: 0,
    last_4_card_digits: null,
    is_reimbursable: 0,
  };

  constructor(
    public router: Router,
    private store: Store<IApplicationState>,
    private dashboardActions: DashboardActions,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private orderService: OrderService,
    private djv: DashboardJobView,
  ) {
    super();
    (window as any)['add'] = this;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.showDialog = true;
    this.onPrepareData();
  }

  onPrepareData() {
    if (this.router.url.includes('/jobs')) {
      this.dashboardActions.requestActiveJobs();
    }

    this.prepareSuppliers();

    this.store
      .pipe(
        select(selectOptions),
        filter((options: IStoreApiItem<IOptions>) => !options.isLoading),
        take(1)
      )
      .subscribe((options: IStoreApiItem<IOptions>) => {
        this.shipTo = options?.data?.shipping_to;
        this.paymentMethod = options?.data?.payment_methods;
        this.documentTypes = options?.data?.document_types;
        this.contactRoles = options?.data?.supplier_roles;
        this.shippingCountries = options?.data?.countries;
        this.shippingStates = options?.data?.states;
      });

    this.store
      .pipe(
        select(selectActiveJobs),
        filter((activeJobs: IStoreApiItem<IActiveJobs>) => !activeJobs.isLoading),
        take(1)
      )
      .subscribe((activeJobs: IStoreApiItem<IActiveJobs>) => {
        this.activeJobs = activeJobs?.data;

        if (this.activeJobs?.length === 1) {
          this.ordersForm.job = this.activeJobs[0].id;
        }
      });

    if (localStorage.getItem('itemBreadcrumb')) {
      setTimeout(() => {
        this.onRequestOrder();
      }, 1000);
    } else {
      this.isLoading = false;
      this.poDocArray.push({
        number: null,
        document_type: 0,
        order_document_date: null,
        attachments: [{
          filename: '',
          attachment: ''
        }],
      });
      this.supplierDocForm = [{
        number: null,
        document_type: 0,
        order_document_date: null,
        attachments: [{
          filename: '',
          attachment: ''
        }],
      }]
    }

  }

  onRequestOrder() {
    this.dashboardActions.requestOrders(localStorage.getItem('itemBreadcrumb'));
    this.store
      .pipe(
        select(selectOrders),
        filter((orders: IStoreApiItem<any>) => !orders.isLoading),
        take(1)
      )
      .subscribe((orders: IStoreApiItem<any>) => {
        this.getPoDocForm(orders?.data?.po_document);
        this.getSupplierDocForm(orders?.data?.supplier_documents);

        this.suppliers?.find((supplier: any) => supplier.id === orders?.data?.supplier);

        this.ordersForm = orders?.data;
        this.ordersForm = JSON.parse(JSON.stringify(this.ordersForm))

        if (orders?.data?.supplier) {
          this.onSelectSupplier(this.suppliers?.find((supplier: any) => supplier.id === orders?.data?.supplier));
        } else {
          this.onClearSupplier()
        }
        if (orders?.data?.supplier_contact) {
          this.onSelectSupplierContact(this.ordersForm?.supplier?.contacts?.find((contact: any) =>
            contact.id === orders?.data?.supplier_contact
          ));
        } else {
          this.onClearSupplierContact();
        }

        this.isLoading = false;
      });
  }

  prepareSuppliers() {
    this.dashboardActions.requestSuppliers();
    this.store
      .pipe(
        select(selectSuppliers),
        filter((suppliers: IStoreApiItem<ISuppliers>) => !suppliers.isLoading),
        take(1)
      )
      .subscribe((suppliers: IStoreApiItem<any>) => {
        this.filteredSuppliers = suppliers?.data?.results;
        this.suppliers = suppliers?.data?.results;
      });
  }

  prepareAutocompleteSuppliers(searchQuerry: any) {
    this.dashboardActions.requestAutocompleteSuppliers({ search_text: searchQuerry });
    this.store
      .pipe(
        select(selectAutocompleteSuppliers),
        filter((suppliers: IStoreApiItem<ISuppliers>) => !suppliers.isLoading),
        take(1)
      )
      .subscribe((suppliers: IStoreApiItem<ISuppliers>) => {
        this.filteredSuppliers = suppliers?.data;
        this.suppliers = suppliers?.data;
      });
  }

  get isSupplierNameRequired() {
    const supplier = { ...this.ordersForm.supplier };
    delete supplier?.name;

    return !this.ordersForm.supplier?.name && !(Object.values(supplier).every(element => element === null));
  }


  documentFormChangeEvent(event: any) {
    if (event) {
      if (event[0].number) {
        this.wrongDocs = false;
      }
    }
    this.supplierDocForm = event;
  }

  poDocFormChangeEvent(event: any) {
    if (event) {
      if (event[0]?.number) {
        this.wrongDocs = false;
      }

      if (event[0]?.document_type === 1) {
        this.poDocForm = event[0];
      }
    }
  }

  supplierFormChangeEvent(event: any) {
    this.addSupplierModal = false;
    this.dashboardActions.requestSuppliers();
    this.prepareSuppliers();
    this.onSelectSupplier(event);
    this.wrongSupplier = false;
  }

  supplierContactFormChangeEvent(event: any) {
    this.addSupplierContactModal = false;
    this.dashboardActions.requestSuppliers();
    this.prepareSuppliers();
    this.onSelectSupplierContact(event);
  }

  closeDialog() {
    this.showDialog = false;
    localStorage.removeItem('itemBreadcrumb');
    this.close.emit();
  }

  closeSupplier() {
    this.addSupplierModal = false;
  }

  closeSupplierContact() {
    this.addSupplierContactModal = false;
  }

  onOpenModalAddSupplier() {
    this.addSupplierModal = true;
  }

  onOpenModalAddSupplierContact() {
    this.addSupplierContactModal = true;
  }

  onSelectSupplier(event: any) {
    this.ordersForm.supplier = {
      id: event.id,
      name: event,
      contacts: event.contacts,
    };

    if (event.id) { this.wrongSupplier = false; }
  }

  onSelectSupplierContact(event: any) {
    this.ordersForm.supplier_contacts = {
      id: event.id,
      name: event,
    };
  }

  onClearSupplier() {
    this.ordersForm.supplier = {
      name: '',
      id: null,
      contacts: null,
    };
    this.onClearSupplierContact();
  }

  onClearSupplierContact() {
    this.ordersForm.supplier_contacts = {
      id: null,
      name: null,
    };
  }

  filterSupplier(event: any) {
    const query = event.query;
    this.filteredSuppliers = this.suppliers.filter((s: any) => {
      return s.name.toLowerCase().search(query) != -1;
    })
  }

  getSuppliers() {
    this.prepareSuppliers();
  }

  filterSupplierContacts(event: any) {
    let supplierContacts: any = this.ordersForm.supplier.contacts;

    this.suppliers?.forEach((element: any) => {
      if (element.id === this.ordersForm.supplier.id) { supplierContacts = element.contacts; }
    });

    const filtered: any[] = [];
    const query = event.query;

    for (let i = 0; i < supplierContacts?.length; i++) {
      const contacts = supplierContacts[i];
      if (contacts.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        filtered.push(contacts);
      }
    }

    this.filteredSupplierContacts = filtered;
  }

  checkJob() {
    this.wrongJob = this.ordersForm?.job?.length <= 1 && !this.ordersForm.id
  }

  itemsAdded: boolean = false;
  onCreateOrder() {
    this.isSubmitted = true;

    //if we are editing an order job is not mandatory
    if (this.ordersForm.id) {
      this.wrongJob = false;
    } else {
      this.wrongJob = this.ordersForm.job?.length < 1;
    }

    this.wrongSupplier = this.ordersForm.supplier?.name?.length < 1;
    if (this.poDocForm.number != null && this.poDocForm.number?.length < 1 && this.supplierDocForm[0].number.length < 1) {
      this.wrongDocs = true;
    } else if (this.supplierDocForm[0].number != null && this.supplierDocForm[0].number?.length < 1 && this.poDocForm.number?.length < 1) {
      this.wrongDocs = true;
    } else if (this.poDocForm.number === null && this.supplierDocForm[0].number === null) {
      this.wrongDocs = true;
    } else {
      this.wrongDocs = false;
    }

    if (!this.wrongDocs && !this.wrongJob && !this.wrongSupplier && this.isDecimal && !this.isLoading) {
      if (!(this.ordersForm.id && this.didAddItems)) {
        this.addItemsModal = true;
      }
      this.handleAddOrder();
      this.isLoading = true;
    }
  }

  handleAddOrder() {
    const objToAdd: INewOrder = this.getAddObject();
    if (this.ordersForm.id) {
      this.callEditRequest(objToAdd);
    } else {
      this.callAddRequest(objToAdd);
    }
  }

  get isDecimal() {
    if (this.ordersForm.total_cost) {
      return CommonConstants.decimalRegex.test(this.ordersForm.total_cost);
    } else { return true; }
  }

  //used to manipulate the data from request response and prepare it for usage in component : add-document
  // deepCopy of this.poDocForm, because the request repsonse is read-only
  // the add-document expects an Array of documents so we have to use poDocArray.
  getPoDocForm(po_document: any) {
    if (po_document) {
      this.poDocForm = po_document;
      this.poDocForm = JSON.parse(JSON.stringify(this.poDocForm))
      if (this.poDocForm.order_document_date != null) {
        const docDate = new Date(this.poDocForm.order_document_date)
        this.poDocForm.order_document_date = getDateWithAPIFormat(docDate);
      }
      this.poDocArray.push(JSON.parse(JSON.stringify(this.poDocForm)));
    } else {
      this.poDocArray.push({
        number: null,
        document_type: 0,
        order_document_date: null,
        attachments: [{
          filename: '',
          attachment: ''
        }],
      })
    }
  }
  // this is similar to the getPoDocForm , however it is an array of document forms
  getSupplierDocForm(supplier_documents: any) {
    if (supplier_documents.length > 0) {
      this.supplierDocForm = supplier_documents;
      this.supplierDocForm = JSON.parse(JSON.stringify(this.supplierDocForm));
      this.supplierDocForm.forEach((docForm: any) => {
        if (docForm.order_document_date) {
          const date = new Date(docForm.order_document_date)
          docForm.order_document_date = getDateWithAPIFormat(date)
        }
      });
    } else {
      this.supplierDocForm = [{
        number: null,
        document_type: 0,
        order_document_date: null,
        attachments: [{
          filename: '',
          attachment: ''
        }],
      }]
    }
  }

  //prepares the payload for POST order Request and returns it
  getAddObject() {
    this.supplierDocForm?.forEach((elem: any) => {
      elem.order_document_date = elem.order_document_date !== null ? this.datePipe.transform(elem.order_document_date, 'yyyy-MM-dd') : null;
      if (elem.attachments) {
        elem.attachments = elem.attachments[0]?.attachment?.length > 1 ? elem.attachments : null;
      }
      if (!elem.id) {
        elem.id = null;
      }
    });

    const objToAdd: INewOrder = {
      job: this.ordersForm.job,
      po_document: this.poDocForm.number !== null && this.poDocForm.number?.length >= 1 ? {
        number: this.poDocForm.number,
        document_type: this.poDocForm.document_type,
        order_document_date: this.poDocForm.order_document_date !== null ? String(this.datePipe.transform(this.poDocForm.order_document_date, 'yyyy-MM-dd')) : null,
        attachments: this.poDocForm.attachments[0]?.attachment?.length > 1 ? this.poDocForm.attachments : null,
        id: this.poDocForm.id ? this.poDocForm.id : null,
      } : null,
      supplier_documents: this.supplierDocForm[0].number !== null && this.supplierDocForm[0].number?.length >= 1 ? this.supplierDocForm : null,
      supplier: this.ordersForm.supplier.id,
      supplier_contact: this.ordersForm.supplier_contacts.id ? this.ordersForm.supplier_contacts.id : null,
      shipping_to: this.ordersForm.shipping_to,
      shipping_name: this.ordersForm.shipping_to === 4 ? this.ordersForm.shipping_name : null,
      shipping_address: this.ordersForm.shipping_to === 4 ? this.ordersForm.shipping_address : null,
      shipping_city: this.ordersForm.shipping_to === 4 ? this.ordersForm.shipping_city : null,
      shipping_zip: this.ordersForm.shipping_to === 4 ? this.ordersForm.shipping_zip : null,
      shipping_country: this.ordersForm.shipping_to === 4 ? this.ordersForm.shipping_country : null,
      shipping_state: this.ordersForm.shipping_to === 4 ? this.ordersForm.shipping_state : null,
      total_cost: this.ordersForm.total_cost,
      payment_method: this.ordersForm.payment_method,
      last_4_card_digits: this.ordersForm.last_4_card_digits,
      is_reimbursable: this.ordersForm.is_reimbursable,
    };

    return objToAdd;
  }

  callAddRequest(payload: any) {
    this.orderService.addOrder({ payload: payload })
      .subscribe((data: any) => {
        this.isLoading = false;
        if (payload.po_document?.number != null) {
          localStorage.setItem('temporaryPo', data?.po_document.id);
        }
        if (payload.supplier_documents != null) {
          localStorage.setItem('temporarySupplier', data?.supplier_documents[0].id);
        }

        if (data?.id) {
          this.curOrderId = this.ordersForm.id = data.id;
        }

        if (this.router.url.includes('/jobs')) {
          this.dashboardActions.requestJobList({ is_archived: false, page_size: 10000 });
        }
      });
  }

  //returns the objToEdit that is the payload needed for the PUT Orders Request
  getEditObject(objToAdd: any) {
    let objToEdit = JSON.parse(JSON.stringify(objToAdd));
    objToEdit.id = this.ordersForm.id;

    objToEdit.po_document?.attachments?.forEach((attachment: any) => {
      if (attachment.id) {
        attachment.attachment = '';
      } else {
        attachment.id = null;
      }
    });

    objToEdit.supplier_documents?.forEach((document: any) => {
      document?.attachments?.forEach((attachment: any) => {
        if (attachment.id) {
          attachment.attachment = '';
        } else {
          attachment.id = null;
        }
      });
    });

    return objToEdit;
  }

  // autocompletes the form with the address values from the Geoapify request
  placeSelected(event: any) {
    if (event) {
      this.ordersForm.shipping_zip = event.properties.postcode ?? null;
      this.ordersForm.shipping_address = event.properties.address_line1;
      this.shippingStates.forEach((state: any) => {
        if (state.value.toLowerCase() == event.properties.state_code.toLowerCase()) { this.ordersForm.shipping_state = state.id; return; }
      });
      if (event.properties.country_code === 'us') {
        this.ordersForm.shipping_country = 1;
      } else if (event.properties.country_code === 'ca') {
        this.ordersForm.shipping_country = 2;
      }
      this.ordersForm.shipping_city = event.properties.city ?? null;
    } else {
      this.ordersForm.shipping_city = null;
      this.ordersForm.shipping_state = null;
      this.ordersForm.shipping_country = null;
      this.ordersForm.shipping_zip = null;
      this.ordersForm.shipping_address = null;
    }
  }

  callEditRequest(payload: any) {
    let objToEdit = this.getEditObject(payload)
    this.dashboardActions.requestUpdateOrder({ ...objToEdit });
    this.store
      .pipe(
        select(selectOrders),
        filter((submOrder: IStoreApiItem<any>) => !submOrder.isLoading),
        takeUntil(this.destroy)
      )
      .subscribe((submOrder: any) => {
        this.isLoading = false;
        if (submOrder?.isSuccess) {
          if (this.router.url.includes('/jobs')) {
            this.dashboardActions.requestJobList({ is_archived: false, page_size: 10000 });
          }

          if (this.didAddItems) {
            this.closeDialog();
            return;
          }

          if (objToEdit.po_document?.number != null) {
            localStorage.setItem('temporaryPo', submOrder?.data?.po_document.id);
          }
          if (objToEdit.supplier_documents != null) {
            localStorage.setItem('temporarySupplier', submOrder?.data?.supplier_documents[0].id);
          }
        }

        if (submOrder?.errors) {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong. Please try again later. If this issue persists, contact support@materlog.com.',
            key: 'error',
          });
        }
      });
  }

  resetForms() {
    this.poDocForm = null;
    this.supplierDocForm = null;
    this.ordersForm = {
      job: '',
      supplier: {
        id: null,
        name: '',
        contacts: null,
      },
      supplier_contacts: {
        id: null,
        name: null,
      },
      shipping_to: 0,
      shipping_name: null,
      shipping_address: null,
      shipping_city: null,
      shipping_zip: null,
      shipping_country: null,
      shipping_state: null,
      total_cost: null,
      payment_method: 0,
      last_4_card_digits: null,
      is_reimbursable: 0,
    };
  }

  backToItems() {
    this.addItemsModal = true;
  }

  getCurrentOrderItems() {
    this.didAddItems = true;
    this.addItemsModal = false;
    setTimeout(() => {
      this.orderService.getOrders({payload: this.curOrderId}).subscribe((result: any) => {
        this.itemList = result.items;
      });      
    }, 500);
  }


  orderUploading: boolean = false;
  uploadDocs: any = [];
  onUploadParseDoc() {
    this.orderUploading = true;
    let completedUploads = 0;
    this.uploadDocs.forEach((o: any) => {
      let formData = o.form;
      formData.append('order_id', '');
      this.orderService.uploadAutoParseDoc(formData).subscribe((result: any) => {
        completedUploads++;
        if (completedUploads == this.uploadDocs.length) {
          this.orderUploading = false;
          this.finishedUpload = true;
          this.djv.do_poll_metadata();
        }
      })
    })
  }

  uploadFile(event: any) {
    event.stopPropagation();
  }

  generateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

  onFileSelected(event: any) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        const cur = input.files[i];
        const reader = new FileReader();

        reader.onloadend = () => {
          const arrayBuffer = reader.result as ArrayBuffer;
          const bytes = new Uint8Array(arrayBuffer);

          if (this.isPDF(bytes) || this.isJPEG(bytes) || this.isPNG(bytes)) {
            if (!this.uploadDocs.find((a: any) => a.filename == cur.name)) {
              const formData = new FormData();
              formData.append('file', cur, cur.name);
              this.uploadDocs.push({ form: formData, filename: cur.name, id: this.generateRandomString() });
            }
          } else {
            alert('File type not supported!');
          }
        };

        reader.onerror = (error) => {
          console.error('Error reading file:', error);
        };

        reader.readAsArrayBuffer(cur.slice(0, 8)); // Read the first 8 bytes
      }
    }
    event.target.value = ''; // reset form
  }

  removeAttachment(event: any, att: any) {
    this.uploadDocs = this.uploadDocs.filter((a: any) => a.id != att.id);
  }

  get orderText() {
    if (this.finishedUpload) return 'Order added';
    return this.ordersForm.id ? 'Edit order' : 'Add an order';
  }

  gotoNotifications() {
    this.gotoNotif.emit();
  }

  isPDF(bytes: Uint8Array): boolean {
    const magic = [0x25, 0x50, 0x44, 0x46, 0x2D];
    return this.checkMagicBytes(bytes, magic);
  }
  
  isJPEG(bytes: Uint8Array): boolean {
    const magics = [
      [0xFF, 0xD8, 0xFF, 0xE0],
      [0xFF, 0xD8, 0xFF, 0xEE],
      [0xFF, 0xD8, 0xFF, 0xE1],
      [0xFF, 0xD8, 0xFF, 0xE0]
    ];
    return magics.some(magic => this.checkMagicBytes(bytes, magic));
  }
  
  isPNG(bytes: Uint8Array): boolean {
    const magic = [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A];
    return this.checkMagicBytes(bytes, magic);
  }
  
  checkMagicBytes(bytes: Uint8Array, magic: number[]): boolean {
    return magic.every((byte, index) => bytes[index] === byte);
  }

}
